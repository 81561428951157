import React, { useState, useEffect, ChangeEvent, KeyboardEvent, ClipboardEvent } from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { Button } from "@fluentui/react-components";
import { toast } from "react-toastify";
import styles from "./AddURLs.module.css";

interface Item {
    id: string;
    value: string;
}
interface MultiTagURLInputProps {
    initialItems: Item[];
    onItemsChange: (items: Item[]) => void;
    cTy: string;
}
const MultiTagURLInput: React.FC<MultiTagURLInputProps> = props => {
    const [items, setItems] = useState<Item[]>(props.initialItems);
    const [currentInput, setCurrentInput] = useState<string>("");

    useEffect(() => {
        props.onItemsChange(items);
    }, [items]);

    const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        setCurrentInput(e.target.value);
    };

    const handleInputKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
        if (e.key === "Enter") {
            e.preventDefault();

            if (props.cTy == "youtube" && currentInput.includes("youtube.com")) {
                addItems(currentInput);
            } else if (props.cTy == "youtube") {
                toast.error("Please enter valid youtube URL/s");
            }
            //console.log("A", props.cTy);
            if (props.cTy == "web" && currentInput.includes("https")) {
                addItems(currentInput);
            } else if (props.cTy == "web") {
                toast.error("Please enter valid Web URL");
            }
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>): void => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text");
        if (props.cTy == "youtube" && pastedText.includes("youtube.com")) {
            addItems(pastedText);
        } else if (props.cTy == "youtube") {
            toast.error("Please enter valid youtube URL/s");
        }
        if (props.cTy == "web" && pastedText.includes("https")) {
            addItems(pastedText);
        } else if (props.cTy == "web") {
            toast.error("Please enter valid Web URL");
        }
    };

    const addItems = (input: string): void => {
        if (input) {
            const newItems: Item[] = input
                .split(/[\n,]+/)
                .map(item => item.trim())
                .filter(item => item !== "")
                .map(item => ({ id: generateId(), value: item }));

            setItems(prevItems => {
                // Create a Set from previous item values for efficient lookup
                const prevItemValues = new Set(prevItems.map(item => item.value));
                // Filter newItems to include only those not present in prevItemValues
                const uniqueNewItems = newItems.filter(newItem => !prevItemValues.has(newItem.value));
                return [...prevItems, ...uniqueNewItems];
            });
            setCurrentInput("");
        }
    };

    const removeItem = (id: string): void => {
        setItems(prevItems => prevItems.filter(item => item.id !== id));
    };

    const generateId = (): string => {
        return Math.random().toString(36).substr(2, 9);
    };

    return (
        <div>
            <textarea
                className={styles.multiInpTextArea}
                value={currentInput}
                onChange={handleInputChange}
                onKeyPress={handleInputKeyPress}
                onPaste={handlePaste}
                placeholder={props.cTy == "youtube" ? "Paste YouTube URL/s" : "Paste URL/s"}
            />

            <div className={styles.list}>
                {items.map((item, i) => (
                    <div className={styles.itmUrl} key={`mti_i_${i}`}>
                        <div className={styles.itmTxt}>{item.value}</div>
                        <Button className={styles.removeFromListButton} icon={<TiDeleteOutline />} onClick={() => removeItem(item.id)}></Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultiTagURLInput;
