import React from "react";
import { DocumentCard, DocumentCardTitle } from "@fluentui/react/lib/DocumentCard";
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import { GrAddCircle } from "react-icons/gr";
import styles from "./GroupCardsList.module.css";
// Define the interface for props
interface CreateNewSpaceCardProps {
    onNewGroupClick: () => void;
}

const CreateNewSpaceCard: React.FC<CreateNewSpaceCardProps> = ({ onNewGroupClick }) => {
    return (
        <DocumentCard
            key={10000}
            className={styles.newSpaceDocCard}
            styles={{
                root: {
                    width: 300,
                    paddingBottom: "20px",
                    paddingTop: "15px",
                    marginBottom: -10,
                    marginTop: 30,
                    borderRadius: 10,
                    textAlign: "center",
                    border: "none",
                    backgroundColor: "rgba(193, 200, 214,0.16)",
                    transition: "box-shadow 0.1s ease-in-out",

                    selectors: {
                        ":hover": {
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                        },
                        ":hover:after": {
                            border: "none"
                        }
                    }
                }
            }}
            onClick={() => onNewGroupClick()}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <DocumentCardTitle
                    title="Create a New Assistant"
                    styles={{
                        root: {
                            font: "normal normal 1000 18px/22px Urbanist, Roboto",
                            color: "#485a73",
                            textAlign: "center",

                            marginBottom: -8,
                            height: "auto"
                        }
                    }}
                />
                <Text
                    variant="large"
                    styles={{
                        root: {
                            textAlign: "center",

                            font: "normal normal normal 14px/19px Urbanist, Roboto",
                            color: "#485a73"
                        }
                    }}
                >
                    An assistant can learn from your content, perform tasks and help you with your work.
                </Text>
                <Text
                    variant="small"
                    styles={{
                        root: {
                            textAlign: "center",
                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                            color: "#485a73"
                        }
                    }}
                >
                    <div className={styles.addCircleIcon}>
                        <GrAddCircle size={35} />
                    </div>
                </Text>
            </Stack>
        </DocumentCard>
    );
};

export default CreateNewSpaceCard;
