import { useState, useEffect, useRef, useContext } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./XplrrPDFSideByChat.module.css";
import stylesCntnt from "./../XplrrPDF/XplrrPDF.module.css";
import { GlobalContext } from "../../GlobalContext";
import { Callout, DirectionalHint, ThemeProvider, IconButton, TooltipHost } from "@fluentui/react";
//import { DragDropContext, Droppable, Draggable, DroppableProvided } from "react-beautiful-dnd";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { HiMiniPlayPause } from "react-icons/hi2";
import { MdDownloading } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import customTheme from "./../../theme/customTheme";
import { fetchSpeechAOI, getClearTextForAudio } from "../../api/aud";
import {
    gXplrrSpaceOrder,
    gXplrrFileOrder,
    gXplrrFileItemHash,
    gXplrrFileItemOpened,
    gXplrrContentOrder,
    gXplrrContentListFileOrder,
    gXplrrSpaceIndex,
    gXplrrExtendedFileList
} from "../../interfaces";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { RiArrowRightWideLine } from "react-icons/ri";
import PageSelector from "../PageSelector/PageSelector";
interface TextItem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}
import XplrrBOXPDF from "../XplrrBOXPDF/XplrrBOXPDF";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { gcontentlist, gpagemap, gfile, gpdfcache, gpdfcachevalue, gXplrrCitationChangeCallback } from "../../interfaces";
import _, { set } from "lodash";
import FileDropdown from "../FileDropdown/FileDropdown";

const excl = ["internal use", "p a g e", "page", "internaluse", "confidential", "Confidential"];
interface ViewerPDFProps {
    startPage: string; // Add this line
    citationFileId: string;
    xplrrCitationChangeCallback: (callBackProps: gXplrrCitationChangeCallback) => void;
    fileId: string;
    refPageNum: string;
    isCitation: boolean;
    rndo?: string;
    searchString: string;
    activeGroupState: string;
    fileList: gfile[];
    shouldPlayAudio: string;
}
let timeoutId: number;

// fileId			PARAM
// activeFileId		CURRENT FILE TO LOAD		[state]
// flId				CITATION FILE [state]

const XplrrPDFSideByChat = ({
    startPage,
    citationFileId,
    xplrrCitationChangeCallback,
    fileId,
    refPageNum,
    isCitation,
    rndo,
    searchString,
    activeGroupState,
    fileList,
    shouldPlayAudio
}: ViewerPDFProps) => {
    const [fileListStateVar, setFileListStateVar] = useState<gfile[]>([]); // Files to Showcase
    const [file, setFile] = useState<gfile>(); // Current File
    const [numPages, setNumPages] = useState<number>(); // Number of Doc Pages

    const [fileMap, setFileMap] = useState<gXplrrSpaceOrder>({}); // Full File⇒Item Map
    const [contentFileOrder, setContentFileOrder] = useState<gXplrrContentListFileOrder>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [audioPage, setAudioPage] = useState(0);
    const [referencePage, setReferencePage] = useState(0);
    const [manualSection, setManualSecton] = useState<number>();
    const [activeCitationState, setActiveCitationState] = useState<string>();
    const [isContentTableShowing, setIsContentTableShowing] = useState<boolean>(false);
    const [flId, setFlId] = useState<string>();
    const [activeFileId, setActiveFileId] = useState<string>();

    const [pageLoaded, setPageLoaded] = useState(true);
    const [perFilesPerSectionStateMap, setPerFilesPerSectionStateMap] = useState<Map<string, Map<string, boolean>>>(new Map());
    const [contentRefreshTriggerIncrement, setContentRefreshTriggerIncrement] = useState(0);
    const { sourceHighlighting, docAudio, selectedGroup } = useContext(GlobalContext);

    // AUDIO
    const [isPlaying, setIsPlaying] = useState(false);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const setActiveCitationStateFunc = (citI: string) => {
        if (citI != activeCitationState) {
            import.meta.env.DEV === true && console.log("***setActvCit_DIFFERENT¦SET***⇒", citI, "¦PREV¦", activeCitationState);
        }
        setActiveCitationState(citI);
    };

    const setActiveFileIdFunction = (flId: string) => {
        //import.meta.env.DEV === true && console.log("setActiveFileId¦FUNTION¦", "flId", flId);
        if (activeFileId !== flId) {
            import.meta.env.DEV === true && console.log("activeFileIdNO EQ flId¦WIPE BASE64", flId);

            //window.globalBase64String = "";
            //window.globalPDFPageDimensions = [];
        } else {
        }
        setActiveFileId(flId);
    };

    const synthesizerRef = useRef<sdk.SpeechSynthesizer | null>(null);
    const audioRef = useRef<sdk.SpeakerAudioDestination | null>(null);
    // Runs from Outside [Citations]
    useEffect(() => {
        //import.meta.env.DEV === true && console.log("[rndo]¦cID", citationFileId, "¦filID¦", fileId, "¦strPge¦", startPage);

        // Prevent Runs before the Space has aligned
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;
        //console.log("[rndo]¦RUN");

        //window.globalUserScrollLazyLoadEnabled = false;

        setActiveCitationStateFunc(citationFileId);

        // fileId			PARAM
        // activeFileId		CURRENT FILE TO LOAD		[state]
        // flId				CITATION FILE [state]

        if (fileId != flId || fileId != activeFileId) {
            //window.globalUserScrollLazyLoadEnabled = false; //!!!

            window.globalBase64String = "";
            window.globalPDFPageDimensions = [];

            setFlId(fileId);
            setActiveCitationState(citationFileId);

            const fileToLoad = fileList.find(f => f.fileid == fileId);
            setActiveFileId(fileId);
            setFile(fileToLoad);

            setCurrentPage(parseInt(startPage));
            setReferencePage(parseInt(startPage));
        } else {
            setReferencePage(parseInt(startPage));
            setHighlightsPerfAfterLoad();
        }
    }, [rndo]);

    // [activeFileId]
    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;

        // fileId			PARAM
        // activeFileId		CURRENT FILE TO LOAD		[state]
        // flId				CITATION FILE [state]
        if (activeCitationState && fileId == flId && activeFileId == flId) {
            setHighlightsPerf();
        }

        const fileMap: gXplrrSpaceOrder = fileMapGen(fileList); // Generate Content List File Order and Item Opened Map
        const cachedFileOrder: gXplrrContentListFileOrder = setContentFileOrderToState(fileList); // Order Items, based on Local State Cache or Default
        const extendedFileList: gXplrrExtendedFileList[] = fileList.map((flfile, flIndex) => {
            return {
                ...flfile,
                globalXplrrOrder: cachedFileOrder[flfile.fileid] == null ? fileMap[parseInt(flfile.fileid)].order : cachedFileOrder[flfile.fileid]
            };
        });
        setFileMap(fileMap); // Preprocess Need data for Dynamic Content List
        setFileListStateVar(extendedFileList.sort((a, b) => a.globalXplrrOrder - b.globalXplrrOrder));
    }, [activeFileId]);

    useEffect(() => {
        if (activeGroupState != selectedGroup.selectionId) return;
        if (fileList.length == 0) return;

        // Preserve File Order to Local Cache
        if (Object.keys(contentFileOrder).length > 0) {
            //import.meta.env.DEV === true && console.log("XPLRR¦UI⇒contentFileOrderState>0");
            const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");
            if (fileOrderCache) {
                const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
                //import.meta.env.DEV === true && console.log("XPLRR¦UI⇒fullFileOrderCacheParsed", fullFileOrderCacheParsed);
                fullFileOrderCacheParsed[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCacheParsed));
            } else {
                //import.meta.env.DEV === true && console.log("XPLRR¦UI⇒fileOrderCache=0", contentFileOrder);
                const fullFileOrderCache: gXplrrSpaceIndex = {};
                fullFileOrderCache[selectedGroup.selectionId] = contentFileOrder;
                localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCache));
            }
        } else {
            import.meta.env.DEV === true &&
                console.log(
                    "XPLR_SBS¦UI⇒fileOrderCache<0 LENGTH. PROBABLY INITIAL RUN",
                    Object.keys(contentFileOrder).length,
                    "contentFileOrder",
                    contentFileOrder
                );
        }
    }, [contentFileOrder]);

    useEffect(() => {
        stopSpeech();
    }, [shouldPlayAudio]);
    const setFileOrderCache = (fileOrderList: gXplrrContentListFileOrder) => {
        let fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");
        if (!fileOrderCache) {
            fileOrderCache = "{}";
        }
        const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
        const fileOrderCacheParsed: gXplrrContentListFileOrder = fullFileOrderCacheParsed[selectedGroup.selectionId];
        fullFileOrderCacheParsed[selectedGroup.selectionId] = fileOrderList;
        localStorage.setItem("xprrContentListFileOrder1", JSON.stringify(fullFileOrderCacheParsed));
    };
    const setContentFileOrderToState = (fileList: gfile[]) => {
        const fileOrderCache = localStorage.getItem("xprrContentListFileOrder1");

        if (fileOrderCache) {
            const fullFileOrderCacheParsed: gXplrrSpaceIndex = JSON.parse(fileOrderCache);
            const fileOrderCacheParsed: gXplrrContentListFileOrder = fullFileOrderCacheParsed[selectedGroup.selectionId];

            //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒fileOrderCacheParsed", fileOrderCacheParsed);
            if (fileOrderCacheParsed && Object.keys(fileOrderCacheParsed)?.length > 0) {
                setContentFileOrder(fileOrderCacheParsed);
                return fileOrderCacheParsed;
            } else {
                const defaultOrder = getDefaultFileOrderFromCache(fileList);
                setContentFileOrder(defaultOrder);
                //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒No fileOrderCache", defaultOrder);
                return defaultOrder;
            }
        } else {
            const defaultOrder = getDefaultFileOrderFromCache(fileList);
            //import.meta.env.DEV === true && console.log("XPLRR¦setContentFileOrderToState⇒No fileOrderCache", defaultOrder);
            setContentFileOrder(defaultOrder);
            return defaultOrder;
        }
    };
    const getDefaultFileOrderFromCache = (fileList: gfile[]) => {
        const defaultOrder: gXplrrContentListFileOrder = {};
        fileList.map((flfile, flIndex) => {
            defaultOrder[flfile.fileid] = flIndex;
        });
        import.meta.env.DEV === true && console.log("XPLRR¦getDefaultFileOrderFromCache", defaultOrder);
        return defaultOrder;
    };

    //Callbackj from PDFXplrr To Determine Current Page on Scroll
    const handleScroll = (pageNumCur: number) => {
        setCurrentPage(pageNumCur);
    };
    const scrollCustomAnimPage = (pg: number) => {
        if (pg > currentPage + 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg > currentPage]", pg, currentPage);
            scrollToPageNoSmooth(pg - 2);
            setTimeout(() => scrollToPageDefault(pg), 50);
        } else if (pg < currentPage - 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg < currentPage]", pg, currentPage);
            scrollToPageNoSmooth(pg + 2);
            setTimeout(() => scrollToPageDefault(pg), 50);
        } else {
            import.meta.env.DEV === true && console.log("scrllCstmAnmPge [pg = currentPage]", pg, currentPage);
            setTimeout(() => scrollToPageDefault(pg), 50);
        }
        reendableUserScrollHandling();
    };
    const scrollCustomAnimElement = (pg: number, el: HTMLElement, scndEl: HTMLElement) => {
        if (pg > currentPage + 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg >>>>>> currentPage]", pg, currentPage, el);

            scrollToPageNoSmooth(pg - 2);
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        } else if (pg < currentPage - 2) {
            //window.globalUserScrollLazyLoadEnabled = false;
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg <<<<<<< currentPage]", pg, currentPage, el);
            scrollToPageNoSmooth(pg + 2);
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        } else {
            //import.meta.env.DEV === true && console.log("scrllCstmAnmElmnt [pg ======= currentPage]", pg, currentPage, el);
            //smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
            setTimeout(() => {
                //import.meta.env.DEV === true && console.log("SMTH_SCRL_TMOUT", pg, currentPage, el);
                smoothScrollIntoView(el, { behavior: "smooth", block: "center" });

                setTimeout(() => {
                    smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
                }, 500);
            }, 50);
        }
        reendableUserScrollHandling(500);
    };
    const scrollToPageAuto = (pN: number) => {
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (Math.abs(currentPage - pN) > 5) {
            import.meta.env.DEV === true && console.log("scrollToPageAuto¦Smooth", pN);
            scrollToElementNoSmooth(node as HTMLElement);
        } else {
            import.meta.env.DEV === true && console.log("scrollToPageAuto¦Direct", pN);
            smoothScrollIntoView(node as HTMLElement, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToPageDefault = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrollToPageDefault¦", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToPageCSTM = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrollToPage¦CSTM", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            smoothScrollIntoView(node, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToPageNoSmooth = (pN: number) => {
        import.meta.env.DEV === true && console.log("scrllTPageNSmth", pN);
        var node = document.querySelector(`[data-page-number="${pN}"`);
        if (node) {
            node.scrollIntoView({ behavior: "auto", block: "center" });
        }
    };
    const scrollToElementAuto = (pN: number, el: HTMLElement) => {
        if (Math.abs(currentPage - pN) > 5) {
            import.meta.env.DEV === true && console.log("scrollToPageAuto¦Smooth", pN);
            scrollToElementNoSmooth(el);
        } else {
            import.meta.env.DEV === true && console.log("scrollToElement", pN);
            smoothScrollIntoView(el, { behavior: "smooth", block: "center" });
        }
    };
    const scrollToElementNoSmooth = (el: HTMLElement) => {
        if (el) {
            el.scrollIntoView({ behavior: "auto", block: "center" });
        }
    };
    const reendableUserScrollHandling = (tm: number = 500) => {
        //import.meta.env.DEV === true && console.log("reendableUserScrollHandling", tm);
        setTimeout(() => (window.globalUserScrollLazyLoadEnabled = true), tm);
    };

    const fileMapGen = (fileList: gfile[]) => {
        let fileMap: gXplrrSpaceOrder = {};
        fileList.map((flfile, flIndex) => {
            let arrItems: gXplrrFileOrder = {
                order: flIndex,
                opened: false,
                fileItemOrder: {}
            };
            if (flfile.contentlist && flfile.contentlist.length > 0) {
                flfile.contentlist.forEach((flItem, flItemIndex) => {
                    const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = contentListValueNumbering(flItem);
                    arrItems.fileItemOrder[flItemIndex] = {
                        opened: false,
                        currentValue: currentValue,
                        splitValues: splitValues,
                        splitLength: splitLength,
                        itemLevel: itemLevel,
                        pageNumExtracted: pageNumExtracted
                    };
                });
            }

            fileMap[parseInt(flfile.fileid)] = arrItems;
        });
        return fileMap;
    };
    const setPages = (numP: number): void => {
        console.log("SetPages", numP);
        setNumPages(numP);

        window.globalTextArray = [];
        window.globalTextArrayAudio = [];
        window.globalTextMap = new Map<string, boolean>();
        window.globalPDFExtr = [];
        window.globalPDFPageMap = new Map<number, any>();
        window.globalPDFContent = [];
        window.globalAllNessesaryPDFPagesLoaded = false;
        window.globalPDFPageDimensions = [];
        window.globalWigglePixel = 0;
    };
    const removeFontColorToMatches = (): void => {
        const elements = document.getElementsByClassName(styles.citHglghtr);
        while (elements.length > 0) {
            elements[0].classList.remove(styles.citHglghtr);
        }
    };
    const removeFontColorToMatchesRed = (): void => {
        const elements = document.getElementsByClassName(styles.citHglghtrRed);
        while (elements.length > 0) {
            elements[0].classList.remove(styles.citHglghtrRed);
        }
    };
    function findRange(numbers: number[], target: number): number[] {
        numbers.sort((a: number, b: number) => a - b);
        let startIndex = numbers.findIndex((num: number) => num > target);
        let upper = startIndex === -1 ? numbers[numbers.length - 1] : numbers[startIndex];
        let endIndex = numbers.lastIndexOf(target);
        let lower = endIndex !== -1 ? numbers[endIndex] : numbers[startIndex - 1];
        if (lower === undefined || upper === undefined) {
            return []; // Return an empty array if target is out of the provided range
        }
        let result = [];
        for (let i = lower; i <= upper; i++) {
            result.push(i);
        }
        if (target === upper) {
            result.push(upper);
        }
        try {
            window.globalpdfObservableRange = result.length;
        } catch {}
        return result;
    }

    const setHighlightsPerf = async () => {
        //import.meta.env.DEV === true && console.log("stHghlghtsPrf¦flId", flId);

        // This is a Navigation Reguest without Highlights
        if (!searchString) {
            import.meta.env.DEV === true && console.log("stHghlghtsPrf¦NO_SEARCH_STRING", "flId", flId, "searchString", searchString);
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage));
            return;
        }

        removeFontColorToMatchesRed();

        if (activeCitationState && isCitation && sourceHighlighting == true) {
            applyHighlightsPerf();
        }
        //import.meta.env.DEV === true && console.log("TRIGGER¦setHighlightsGloV3 from [stHghlghtsPrf]");
        const allResults = await setHighlightsGloV3();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) {
            import.meta.env.DEV === true && console.log(`RESULT_NOT_FOUND¦setHighlightsPerf¦scrollToPageNoSmooth[${startPage}]`);
            if (startPage) {
                import.meta.env.DEV === true && console.log("CALL¦scrollToPage");
                scrollToPageNoSmooth(parseInt(startPage));
            }
        }
        reendableUserScrollHandling(100);
    };

    const setHighlightsPerfAfterLoad = async () => {
        //import.meta.env.DEV === true && console.log("stHghlghtsPrfAftrLd¦INIT", "flId", flId);

        // This is a Navigation Reguest without Highlights
        if (!searchString) {
            //import.meta.env.DEV === true && console.log("stHghlghtsPrfAftrLd¦NO_SEARCH_STRING", "flId", flId, "searchString", searchString);
            setCurrentPage(parseInt(startPage));
            scrollCustomAnimPage(parseInt(startPage));
            return;
        }

        removeFontColorToMatchesRed();
        if (activeCitationState && sourceHighlighting == true) {
            applyHighlightsPerf();
        }
        //import.meta.env.DEV === true && console.log("TRIGGER¦setHighlightsGloV3_from_[stHghlghtsPrfAftrLd]");
        const allResults = await setHighlightsGloV3();
        const allResultsNull = allResults?.every(result => result === null);
        if (allResultsNull) {
            console.log("RESULT_NOT_FOUND");
            if (startPage) {
                scrollToPageNoSmooth(parseInt(startPage));
                reendableUserScrollHandling();
            }
        }
        setPageLoaded(true);
    };

    function cleanText(text: string | null) {
        if (!text) return "";
        return text.replace(/\r?\n/g, "").replace(/\s+/g, "");
    }

    const waitForPdfPage = async (index: number, maxAttempts: number = 50): Promise<true | null> => {
        const isReactPdfPage = (element: Element | null): boolean => {
            if (!element || !(element instanceof HTMLElement)) {
                return false;
            }
            return element.tagName.toLowerCase() === "div" && element.classList.contains("react-pdf__Page");
        };
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            import.meta.env.DEV === true && console.log(`PAGE LOAD CHECK ${index}`);
            const element = document.querySelectorAll(`div[data-page-number="${index}"]`);
            import.meta.env.DEV === true && console.log("EL", element);
            if (isReactPdfPage(element[0])) {
                import.meta.env.DEV === true && console.log(`PDF LOADED ${index}`);
                return true;
            }
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        console.log(`PDF PAGE LOAD TIMEOUT`);
        return null;
    };
    const waitForAllPDFItems = async (index: number, maxAttempts: number = 5000): Promise<true | null> => {
        const isReactPdfPageTextRendererDone = (index: number): boolean => {
            let itemProgress = window.globalItemCounts?.get(index);
            if (itemProgress !== undefined && itemProgress.flag == true) {
                let pdfPageItemCount = document.querySelectorAll(`.pdf_itm_${index}`);
                if (pdfPageItemCount.length == itemProgress.count) {
                    //import.meta.env.DEV === true &&
                    //    console.log(`waitFrItms¦XPLR_SBS_PAGE_RENDERED ¦ PAGE ${index + 1} ¦ ${pdfPageItemCount.length} ⇔ ${itemProgress.count}`);
                    return true;
                } else {
                    //import.meta.env.DEV === true &&
                    //    console.log(`waitFrItms¦XPLR_SBS_WAIT_RENDER ¦ PAGE ${index + 1} ¦ ${pdfPageItemCount.length} ⇔ ${itemProgress.count}`);
                    setReferencePage(index + 1);
                    return false;
                }
            } else {
                return false;
            }
        };
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            //console.log(`PAGE LOAD CHECK ${index}`);
            if (isReactPdfPageTextRendererDone(index)) {
                return true;
            }
            await new Promise(resolve => setTimeout(resolve, 50));
        }
        console.warn(`waitFrItms¦FAILED_RENDER_WAIT⇒ ${index} AFTER ${maxAttempts} ATTEMPTS`);
        return null;
    };

    const filterString = (str: string) => {
        return str
            .toLowerCase()
            .replace(/[^а-яa-z0-9]/gi, "") // Keep Cyrillic, Latin letters, and digits
            .trim();
    };

    interface highLightItem {
        startIndex: number;
        endIndex: number;
        element: HTMLElement;
        processedText: string;
    }
    const findMatchingSequenceRGX = async (pg: number[]) => {
        //import.meta.env.DEV === true && console.log("fndMtchngSqncRGX", pg);
        const filterString = (str: string | undefined): string => {
            return (str || "")
                .toLowerCase()
                .replace(/[^а-яa-z0-9]/gi, "")
                .trim();
        };

        const filteredTargetString = filterString(searchString);
        const rgx = prepareRegex(filteredTargetString);

        const selectorPG = pg.map(pg => `.pdf_itm_${pg - 1}`).join(", ");
        const items = document.querySelectorAll(selectorPG);
        const itemsArray = Array.from(items);

        let processedText = "";
        let elementMap: highLightItem[] = [];

        for (let index = 0; index < itemsArray.length; index++) {
            const item = itemsArray[index] as HTMLElement;
            const originalItemText = item.textContent?.trim();
            const itemText = filterString(originalItemText);
            if (itemText.length > 0) {
                elementMap.push({
                    startIndex: processedText.length,
                    endIndex: processedText.length + itemText.length - 1,
                    element: item as HTMLElement,
                    processedText: itemText
                });
                processedText += itemText;
            }
        }
        //console.log("¦RGX¦", rgx, "¦processedText¦", processedText);
        const matches = [...processedText.matchAll(rgx)];

        matches.forEach(match => {
            let startIndex = match.index || 0;

            const endIndex = startIndex + match[0].length - 1;

            const matchingElements = elementMap.filter(
                item =>
                    (item.startIndex <= startIndex && startIndex <= item.endIndex) ||
                    (item.startIndex <= endIndex && endIndex <= item.endIndex) ||
                    (startIndex <= item.startIndex && item.endIndex <= endIndex)
            );
            //import.meta.env.DEV === true && console.log(`fndMtchngSqncRGX¦MATCH ⇒ "${match[0]}" FOUND_IN ${matchingElements.length} Elements:`);
            matchingElements.forEach((item, index) => {
                if (index == matchingElements.length - 1) {
                    //import.meta.env.DEV === true && console.log("******************SCROLL_TO_HIGHLIGHT****************** PG⇒", pg, matchingElements);
                    //window.globalUserScrollLazyLoadEnabled = false;
                    if (window.globalHiglightFound == true) {
                        //import.meta.env.DEV === true && console.log("glbalHglghtFnd NOT TRUE", window.globalHiglightFound);
                    } else {
                        //import.meta.env.DEV === true && console.log("glbalHglghtFnd", window.globalHiglightFound, "true");
                        window.globalHiglightFound = true;
                        try {
                            let itmPg = parseInt(item.element.id.split("_")[1]);
                            //import.meta.env.DEV === true && console.log("SCROLL_TO_Highlight⇒ PG Extracted from Element ID", item.element.id);

                            let skndEl = item.element;

                            if (index > 0 && skndEl.innerText.length < 5) {
                                skndEl = matchingElements[index - 1].element;
                            }

                            scrollCustomAnimElement(itmPg, skndEl, skndEl);
                        } catch {
                            //import.meta.env.DEV === true && console.log("ERROR SCROLL_TO_Highlight⇒Cannot Determine Item Page by Item ID", item.element);
                            smoothScrollIntoView(item.element as HTMLElement, { behavior: "smooth", block: "center" });
                        }
                    }
                }
                item.element.classList.add(styles.citHglghtrRed);
            });
        });

        if (matches.length > 0 && matches[0].index !== undefined) {
            return { startIndex: matches[0].index, endIndex: matches[0].index + matches[0][0].length - 1 };
        } else {
            return null;
        }
    };

    const prepareRegex = (filterString: string) => {
        // Split the string in half
        const halfLength = Math.floor(filterString.length / 2);
        let startPart = filterString.slice(0, halfLength);
        let endPart = filterString.slice(halfLength);

        // Get the first and last 10 characters (or less if the string is shorter)
        startPart = startPart.slice(0, 15);
        endPart = endPart.slice(-15);

        // Escape special regex characters
        const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        startPart = escapeRegex(startPart);
        endPart = escapeRegex(endPart);

        // Create the regex pattern
        const regexPattern = `${startPart}[\\s\\S]*?${endPart}`;

        return new RegExp(regexPattern, "g");
    };

    const setHighlightsV3 = async (page: number, i: number, lignth: number) => {
        //import.meta.env.DEV === true && console.log("¦stHghlghtsV3¦page", page, "¦i¦", i);

        await waitForAllPDFItems(page - 1, 50);
        if (window.globalHiglightFound == false) {
            if (i == lignth - 1) {
                const difBetweenSrcAndTrgPage = currentPage - page;
                if (Math.abs(difBetweenSrcAndTrgPage) > 5) {
                    if (difBetweenSrcAndTrgPage < 0) {
                        import.meta.env.DEV === true && console.log(`scrollToPage¦FROM ${currentPage} ⇒ ${currentPage + 1}`);
                        scrollToPageCSTM(currentPage + 1);
                    } else {
                        import.meta.env.DEV === true && console.log(`scrollToPage¦FROM ${currentPage} ⇒ ${currentPage - 1}`);
                        scrollToPageCSTM(currentPage - 1);
                    }
                }
            }
        }

        const result = await findMatchingSequenceRGX([page]);
        return result;
    };

    const wigglePixel = async () => {
        if (window.globalWigglePixel == 1) {
            return;
        }
        window.globalWigglePixel = 1;
        while (window.globalHiglightFound == false) {
            await document.getElementById("pdfmainid9")?.scrollBy(1, 0);
            await new Promise(r => setTimeout(r, 100));

            await document.getElementById("pdfmainid9")?.scrollBy(-1, 0);
            await new Promise(r => setTimeout(r, 100));
            if (window.globalWigglePixel == 0) {
                break;
            }
        }
        window.globalWigglePixel = 0;
    };

    const setHighlightsGloV3 = async () => {
        if (file?.indexchunks) {
            //const highlightPages: number[] = findRange(file.indexchunks, parseInt(startPage));
            const highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));

            //import.meta.env.DEV === true &&
            //    console.log("¦highlightPagesV3¦startPage", parseInt(startPage), "¦highlightPages¦", highlightPages, "file.indexchunks", file.indexchunks);
            //import.meta.env.DEV === true && console.log("glbalHglghtFnd", window.globalHiglightFound, "false");

            window.globalHiglightFound = false;
            const promises = highlightPages.map((page, index) => setHighlightsV3(page, index, highlightPages.length));
            wigglePixel();
            const results = await Promise.all(promises);
            const allResultsNull = results?.every(result => result === null);
            if (allResultsNull) {
                //import.meta.env.DEV === true && console.log("¦highlightPagesV3¦ALL_RESULTS_NULL¦RETRY_FULL");
                const results = await findMatchingSequenceRGX(highlightPages);
                //import.meta.env.DEV === true && console.log("¦highlightPagesV3¦FIN", results);
                return [results];
            } else {
                //import.meta.env.DEV === true && console.log("¦highlightPagesV3¦FIN", results);
                return results;
            }
        }
    };

    const applyHighlightsPerf = () => {
        if (file?.indexchunks) {
            //const highlightPages: number[] = findRange(file.indexchunks, parseInt(startPage));
            const highlightPages: number[] = findRange([...file.indexchunks, file.pagedimensions.length], parseInt(startPage));
            for (let i = 0; i < highlightPages.length; i++) {
                document.querySelectorAll(`div[data-page-number="${highlightPages[i]}"] span`).forEach(span => {
                    //console.log("HL", span);
                    (span as HTMLElement).classList.add(styles.citHglghtr);
                });
            }
        }
    };

    // Define the function to call after the timeout
    const handleTimeout = () => {
        reendableUserScrollHandling();

        // fileId			PARAM
        // activeFileId		CURRENT FILE TO LOAD		[state]
        // flId				CITATION FILE [state]

        if (fileId == activeFileId) {
            setHighlightsPerf();
        }
        //import.meta.env.DEV === true && console.log("XPLRRBOX⇒Timeout");
        setPageLoaded(true);
    };

    const setContentList = (contentList: { title: string; dest: number }[]) => {
        import.meta.env.DEV === true && console.log("VWRPDF¦setContentList", contentList);
    };

    const getCurrentPage = () => {
        const container = document.getElementById("pdfmainid9");
        if (container) {
            let { scrollTop, clientHeight, scrollHeight } = container;
            let pageHeight = (scrollHeight ? scrollHeight : 1) / (numPages ? numPages : 1);
            let pp = Math.ceil((scrollTop + clientHeight - clientHeight / 3) / pageHeight);
            return pp;
        }
        return 0;
    };
    function contentListValueNumbering(item: gcontentlist) {
        const currentValue = item.destPageNum;

        if (item.srcText) {
            let splitValues = item.srcText.trim().replace(" .", ".").split(" ");
            let pageNum = item.destPageNum;
            if (!isNaN(Number(splitValues[splitValues.length - 1]))) {
                pageNum = parseInt(splitValues[splitValues.length - 1]);
                splitValues.pop();
            }

            if (!splitValues || splitValues.length == 0) {
                return {
                    currentValue: "",
                    splitValues: [],
                    splitLength: 0,
                    itemLevel: 0,
                    pageNumExtracted: 0
                };
            }

            return {
                currentValue: currentValue.toString(),
                splitValues: splitValues,
                splitLength: splitValues.length,
                itemLevel: (addDotIfNeeded(splitValues[0]).match(/\./g) || []).length - 1,
                pageNumExtracted: pageNum
            };
        } else {
            return {
                currentValue: "",
                splitValues: [],
                splitLength: 0,
                itemLevel: 0,
                pageNumExtracted: 0
            };
        }
    }
    const handlePageChangeFromPageSelector = (event: React.ChangeEvent<HTMLSelectElement>) => {
        window.globalUserScrollLazyLoadEnabled == true;

        setManualSecton(Number(event.target.value));

        setCurrentPage(Number(event.target.value));
        scrollToPageNoSmooth(Number(event.target.value));

        reendableUserScrollHandling();
    };

    const handleClick = (target: EventTarget, item: gcontentlist, indx: number) => {
        //window.globalUserScrollLazyLoadEnabled == true;
        //setManualSecton(item.destPageNum);
        scrollToPageNoSmooth(item.destPageNum);
        setCurrentPage(item.destPageNum);
        reendableUserScrollHandling();
    };

    const handleFileClick = (target: EventTarget, fl: gfile, flIndex: number) => {
        import.meta.env.DEV === true && console.log("handleFileClick¦setActiveFileId", fl.fileid);

        window.globalUserScrollLazyLoadEnabled == true;

        setFile(fl);
        setActiveFileIdFunction(fl.fileid);

        setCurrentPage(1);

        reendableUserScrollHandling(100);
    };
    const addDotIfNeeded = (str: string) => {
        return str.endsWith(".") ? str : str + ".";
    };

    const expandCollapseSection = (flId: string, srcText: string) => {
        setPerFilesPerSectionStateMap(prevMap => {
            const newMap = new Map(prevMap);
            const fileMap = newMap.get(flId) || new Map();
            const newFileMap = new Map(fileMap);

            newFileMap.set(srcText, !fileMap.get(srcText));
            newMap.set(flId, newFileMap);

            return newMap;
        });
    };

    useEffect(() => {
        setContentRefreshTriggerIncrement(prevValue => prevValue + 1);
    }, [perFilesPerSectionStateMap]);

    function renderLinkData() {
        console.log("renderLinkData");
        if (!file || !fileMap || (fileMap && Object.keys(fileMap).length < 1)) return null;

        let weAreOver = true;
        let weAreOverRef = true;
        let setActive = false;
        let setActiveRef = false;
        const rndrlmnt: JSX.Element[] = [];
        const fllmnt: JSX.Element[] = [];

        let fl = file;
        let flIndex = 0;

        if (fl.fileid == activeFileId) {
            const linkData = fl.contentlist;
            let linkPageNumTracking = 0;
            let previtemLevel = 0;
            let linkDataLength = linkData.length;
            let lvlZeroItem: gcontentlist;
            linkData?.map((item, index) => {
                if (item.srcText !== "") {
                    const { currentValue, splitValues, splitLength, itemLevel, pageNumExtracted } = fileMap[parseInt(fl.fileid)]?.fileItemOrder[index];
                    const {
                        currentValue: currentValueNext,
                        splitValues: splitValuesNext,
                        splitLength: splitLengthNext,
                        itemLevel: itemLevelNext,
                        pageNumExtracted: pageNumExtractedNext
                    } = fileMap[parseInt(fl.fileid)].fileItemOrder[index + 1 < linkDataLength ? index + 1 : index];
                    let nextItem = linkData[index + 1 < linkDataLength ? index + 1 : index];
                    if (splitValues.join("").length < 3) {
                        return;
                    }

                    if (item.destPageNum < linkPageNumTracking) {
                        return;
                    }
                    linkPageNumTracking = item.destPageNum;
                    let samePageLink = false;
                    if (index > 0 && linkData[index - 1].destPageNum == item.destPageNum) {
                        samePageLink = true;
                    }

                    if (weAreOver != currentPage > item.destPageNum) {
                        setActive = true;
                    }
                    weAreOver = currentPage > item.destPageNum;
                    if (weAreOverRef != referencePage > item.destPageNum) {
                        setActiveRef = true;
                    }
                    weAreOverRef = referencePage > item.destPageNum;

                    let itemClass = `${stylesCntnt.pdfContListNormal} ${setActive == true ? stylesCntnt.pdfContListSelected : ""} ${
                        setActiveRef == true ? stylesCntnt.pdfContListBold : ""
                    }`;
                    let contClass = `${itemLevel == 0 ? stylesCntnt.contentItem : itemLevel == 1 ? stylesCntnt.contentItemSub : stylesCntnt.contentItemSub2}`;
                    if (itemLevel == 0) {
                        lvlZeroItem = item;
                        fllmnt.push(
                            <div className={stylesCntnt.itemContBacka} key={index}>
                                <div key={index} className={itemClass}>
                                    <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                        <div className={stylesCntnt.contentItemNumbeing}>{splitValues[0]}</div>
                                        <div className={stylesCntnt.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                        <div
                                            className={`${itemLevelNext > itemLevel ? stylesCntnt.contentPageNum : styles.contentPageNumNoExpandNeeded}`}
                                            onClick={e => {
                                                if (itemLevelNext > itemLevel) {
                                                    e.stopPropagation();
                                                    expandCollapseSection(fl.fileid, item.srcText);
                                                }
                                            }}
                                        >
                                            <div>{pageNumExtracted}</div>
                                            {itemLevelNext > itemLevel ? (
                                                perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem.srcText) == true ? (
                                                    <div className={stylesCntnt.expandContentSubItemsIcon}>
                                                        <IoIosArrowDown size={15} />
                                                    </div>
                                                ) : (
                                                    <div className={stylesCntnt.expandContentSubItemsIcon}>
                                                        <RiArrowRightWideLine size={15} />
                                                    </div>
                                                )
                                            ) : (
                                                <div className={styles.expandContentSubItemsNoIcon}></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        if (perFilesPerSectionStateMap.get(fl.fileid)?.get(lvlZeroItem.srcText) == true) {
                            if (itemLevel == 1) {
                                contClass = `${stylesCntnt.contentItemSub}`;
                                fllmnt.push(
                                    <div className={stylesCntnt.itemContBacka} key={index}>
                                        <div key={index} className={itemClass}>
                                            <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                                <div className={stylesCntnt.contentItemNumbeing}>{splitValues[0]}</div>
                                                <div className={stylesCntnt.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                                <div className={stylesCntnt.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if (itemLevel >= 2) {
                                contClass = `${stylesCntnt.contentItemSub2}`;

                                fllmnt.push(
                                    <div key={index}>
                                        <div className={stylesCntnt.itemContBacka} key={index}>
                                            <div key={index} className={itemClass}>
                                                <div onClick={event => handleClick(event.target, item, index)} className={contClass}>
                                                    <div className={stylesCntnt.contentItemNumbeing}>{splitValues[0]}</div>
                                                    <div className={stylesCntnt.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                                    <div className={stylesCntnt.contentPageNumNoExpandNeeded}>{pageNumExtracted}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                    if (setActive == true) {
                        setActive = false;
                    }
                    if (setActiveRef == true) {
                        setActiveRef = false;
                    }
                }
            });
        }

        return fllmnt;
    }
    const handleDragStart = (result: any) => {
        import.meta.env.DEV === true && console.log("DND¦START");
    };
    const handleDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!destination || destination.index == source.index) {
            return;
        }
        let array = Object.keys(contentFileOrder).map(key => ({ key, order: contentFileOrder[key] }));
        array.sort((a, b) => a.order - b.order);
        const itemIndex = array.findIndex(item => item.key == result.draggableId);
        import.meta.env.DEV === true &&
            console.log("DND_BEF¦itemIndex", itemIndex, "destination.index", destination.index, "result.draggableId", result.draggableId, "");

        const [item] = array.splice(itemIndex, 1);
        //if (destination.index > itemIndex) {
        //    destination.index = destination.index - 1;
        //    item.order = destination.index - 1;
        //}
        array.splice(destination.index, 0, item);
        array.forEach((item, index) => {
            item.order = index;
        });

        import.meta.env.DEV === true && console.log("DND_AFT¦destination.index", destination.index, "item.order", item.order);
        import.meta.env.DEV === true && console.log("DND_ARR", array);

        // Step 6: Convert back to a map
        let updatedContentFileOrder: gXplrrContentListFileOrder = {};
        array.forEach(item => {
            updatedContentFileOrder[item.key] = item.order;
        });
        import.meta.env.DEV === true && console.log("DND_MAP", updatedContentFileOrder);

        setContentFileOrder(updatedContentFileOrder);
        const extendedFileList: gXplrrExtendedFileList[] = fileList.map((flfile, flIndex) => {
            return { ...flfile, globalXplrrOrder: updatedContentFileOrder[flfile.fileid] };
        });

        import.meta.env.DEV === true && console.log("DND_EXTLIST", extendedFileList);

        setFileListStateVar(extendedFileList.sort((a, b) => a.globalXplrrOrder - b.globalXplrrOrder));
    };
    const playSpeech = () => {
        console.log("Play_Speech");
        if (audioRef.current && audioPage == currentPage) {
            audioRef.current.resume();
            setIsPlaying(true);
        } else {
            audioRef.current = null;
            setIsLoadingAudio(true);
            fetchSpeechAudio(currentPage);
        }
    };
    const stopSpeech = () => {
        //console.log("Stop_Speech");
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleAudioAOI = async (page: number, retryCount = 0) => {
        setCurrentPage(page);
        let text = getClearTextForAudio(page);
        if (text && text.trim().length > 0) {
            let aud = await fetchSpeechAOI(text);
            if (aud) {
                aud.loop = false;
                aud.onended = () => {
                    if (numPages && page + 1 < numPages) {
                        scrollToPageDefault(page + 1);
                        handleAudioAOI(page + 1);
                    }
                };
                setAudio(aud);
                aud.play();
                setIsLoadingAudio(false);
                setIsPlaying(true);
            }
        } else {
            console.log("Advance_Page_No_Content", page, numPages);
            if (numPages && currentPage < numPages) {
                scrollToPageDefault(page + 1);
                handleAudioAOI(page + 1);
            }
        }
    };

    const fetchSpeechAudio = async (page: number, retryCount = 0) => {
        const reg = "westeurope";
        const apk = "91c9d27ba50e483783a44465c41f24da";

        console.log("fetchSpeechAudio¦", page);

        setCurrentPage(page);
        setAudioPage(page);

        const items = document.querySelectorAll(`.pdf_itm_${page - 1}`);
        const text = Array.from(items)
            .filter(
                item =>
                    item.textContent != null && // Exclude if string is empty
                    !/^\d+$/.test(item.textContent) && // Exclude if string is only digits
                    !excl.includes(item.textContent.toLowerCase().trim()) && // Exclude if string is in the exclusion array
                    item.textContent.trim() !== "|"
            )
            .map(item => item.textContent)
            .join(" ")
            .replace("CSoft", "Сисофт");

        if (text && text.trim().length > 0) {
            try {
                let player = new sdk.SpeakerAudioDestination();
                player.onAudioEnd = function (_) {
                    if (numPages && page + 1 < numPages) {
                        console.log("SPCH_SNTH_NEXT_PAGE", page + 1);
                        scrollToPageDefault(page + 1);
                        player.close();

                        fetchSpeechAudio(page + 1);
                    }
                };
                player.onAudioStart = function (_) {
                    console.log("onAudioStart");
                };
                const containsCyrillic = /[а-яА-ЯЁё]/.test(text);
                let voiceP = "en-US-AvaNeural";
                let lcle = "en-US";
                if (containsCyrillic) {
                    voiceP = "bg-BG-KalinaNeural";
                    lcle = "bg-BG";
                }
                audioRef.current = player;
                var audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);
                const speechConfig = sdk.SpeechConfig.fromSubscription(apk, reg);
                speechConfig.speechSynthesisVoiceName = voiceP;

                const synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
                synthesizer.synthesisCompleted = (sender, event) => {
                    console.log("Synthesis_Completed");
                };
                synthesizer.synthesizing = (sender, event) => {
                    console.log("Synthesizing");
                };

                synthesizerRef.current = synthesizer;
                setIsPlaying(true);

                synthesizer.speakTextAsync(
                    text,
                    result => {
                        console.log("RSLT¦", result);
                        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                            console.log("SPCH_SNTH_ERR_SynthesizingAudioCompleted", result);
                            setIsLoadingAudio(false);
                            synthesizer.close();
                        } else {
                            setIsLoadingAudio(false);
                            console.log("SPCH_SNTH_ERR", result);
                            synthesizer.close();
                        }
                    },
                    error => {
                        console.error("Error: ", error);
                        setIsPlaying(false);
                        synthesizer.close();
                    }
                );
            } catch (error: any) {
                console.error("Error Fetching or Playing Speech¦", error);
            }
        } else {
            console.log("Advance_Page_No_Content", page, numPages);
            if (numPages && currentPage < numPages) {
                console.log("numPages && currentPage < numPages", page + 1);
                scrollToPageDefault(page + 1);
                fetchSpeechAudio(page + 1);
            }
        }
    };
    const playButStyles = {
        root: {
            left: "102px",

            margin: "5px 5px",
            borderRadius: "5px"
        }
    };

    const pauseButStyles = {
        root: {
            left: "102px",
            backgroundColor: "#ff75fd",
            margin: "5px 5px",
            borderRadius: "5px"
        }
    };
    const callBackVisibleEntries = (visibleEntries: number[]) => {};
    const callBackIsLoading = (isLoading: boolean) => {
        console.log("Is_Loading¦", isLoading);
        import.meta.env.DEV === true && console.log("callBackIsLoading¦", currentPage);
    };
    const onContentShow = (fl: gfile | undefined, flIndex: number | undefined) => {
        import.meta.env.DEV === true && console.log("onContentShow", fl, flIndex);
        setIsContentTableShowing(true);
    };
    return (
        <ThemeProvider theme={customTheme}>
            <div className={styles.Example}>
                {/* TITLE, PAGE SELECTOR, SPEAK */}

                <div className={styles.headerFileDropdown}>
                    <FileDropdown
                        file={file}
                        fileListStateVar={fileListStateVar}
                        handleFileClick={handleFileClick}
                        onContentShow={onContentShow}
                        activeFileId={activeFileId}
                    />
                </div>

                {isContentTableShowing && (
                    <Callout
                        target={"#pdfTtldrpTrg"}
                        className={styles.fileListCallout}
                        onDismiss={() => setIsContentTableShowing(false)}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        isBeakVisible={false}
                    >
                        <div className={styles.cntntf}>{renderLinkData()}</div>
                    </Callout>
                )}

                {docAudio && (
                    <div className={styles.volButsContainer}>
                        <div className={styles.volButs}>
                            {/* PAGE SELECTOR */}
                            <div className={styles.pageSelectorXplrr}>
                                <div className={styles.pageSelectorOnly}>
                                    <PageSelector
                                        currentPage={currentPage}
                                        numPages={numPages ? numPages : 0}
                                        handlePageChange={handlePageChangeFromPageSelector}
                                    ></PageSelector>
                                </div>
                                <div className={styles.totPagesDisplay}>/ {numPages}</div>
                            </div>
                            {/* SPEAK BUTTON */}
                            <TooltipHost
                                            content="Speak Page"
                                            directionalHint={DirectionalHint.topCenter}
                                        >
                            <IconButton
                                onClick={() => (isPlaying ? stopSpeech() : playSpeech())}
                                disabled={pageLoaded == true ? false : true}

                                styles={isPlaying || isLoadingAudio ? pauseButStyles : playButStyles}
                                className={selectedGroup.enabledocaudio == 1 ? styles.speakButShow : styles.speakButHide}
                            >
                                {isPlaying ? (
                                    <HiOutlineVolumeOff size={35} />
                                ) : isLoadingAudio ? (
                                    <MdDownloading size={35} />
                                ) : audioRef.current && audioPage == currentPage ? (
                                    <HiMiniPlayPause size={35} />
                                ) : (
                                    <HiOutlineVolumeUp size={35} />
                                )}
                            </IconButton>
                            </TooltipHost>
                        </div>
                    </div>
                )}

                <div className={styles.pdfWrapXplrr} id="pdfWrapXplrrIDENT">
                    {/* @UI PDFBOX WIDTH PDFXplrr*/}

                    <div className={styles.pdfViewC}>
                        <XplrrBOXPDF
                            file={file}
                            startPage={referencePage}
                            activeFileId={activeFileId ? activeFileId : ""}
                            handleTimeout={handleTimeout}
                            handleScroll={handleScroll}
                            setPages={setPages}
                            setContentList={setContentList}
                            callBackVisibleEntries={callBackVisibleEntries}
                            callBackIsLoading={callBackIsLoading}
                            srcLoc="sbs"
                            companyId={selectedGroup.companyid}
                        ></XplrrBOXPDF>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
};

export default XplrrPDFSideByChat;
