import React from "react";
import { DocumentCard, DocumentCardDetails, DocumentCardTitle, DocumentCardType, IDocumentCardDetailsProps } from "@fluentui/react/lib/DocumentCard";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack, IStackTokens } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import { mergeStyles, AnimationStyles } from "@fluentui/react/lib/Styling";
import { ImFilePdf } from "react-icons/im";
import { FaYoutube } from "react-icons/fa";
import { LiaPagerSolid } from "react-icons/lia";
import { FaFilePdf } from "react-icons/fa";
import styles from "./detailedList.module.css";
const stackTokens: IStackTokens = { childrenGap: 20 };

type UploadType = "pdf" | "youtube" | "web";

interface UploadOption {
    title: string;
    icon: string;
    gradient: string;
    type: UploadType;
    subtitle: string;
}

const uploadOptions: UploadOption[] = [
    {
        title: "Add Documents",
        icon: "PDF",
        gradient: "linear-gradient(135deg, #ff6b6b, #feca57)",
        type: "pdf",
        subtitle: "Upload files, get insights, complete tasks"
    },
    {
        title: "Add YouTube",
        icon: "Video",
        gradient: "linear-gradient(135deg, #ff9ff3, #feca57)",
        type: "youtube",
        subtitle: "Add videos, I will understand both video and audio"
    },
    {
        title: "Add Web Pages",
        icon: "Globe",
        gradient: "linear-gradient(135deg, #54a0ff, #5f27cd)",
        type: "web",
        subtitle: "Add Web Pages, I can help you with keeping track of research"
    }
];

const cardClass =
    window.innerWidth > 800
        ? mergeStyles({
              width: 200,
              height: 200,
              margin: "10px",
              borderRadius: "15px",
              border: "none",
              boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.3s cubic-bezier(.25,.8,.25,1)"
          })
        : mergeStyles({
              width: 100,
              height: 100,
              margin: "10px",
              borderRadius: "15px",
              border: "none",
              boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              transition: "all 0.3s cubic-bezier(.25,.8,.25,1)"
          });

const iconClass =
    window.innerWidth > 800
        ? mergeStyles({
              fontSize: 48,
              fontFamily: "Urbanist",
              color: "#333"
          })
        : mergeStyles({
              fontSize: 23,
              fontFamily: "Urbanist",
              color: "#333"
          });

const titleClass = mergeStyles({
    fontWeight: "bold",
    fontFamily: "Urbanist",
    fontSize: "18px",
    color: "#333",
    marginBottom: "5px",
    textAlign: "center",

    flexDirection: "column",
    justifyContent: "center"
});

const subtitleClass = mergeStyles({
    fontSize: "14px",
    fontFamily: "Urbanist",
    color: "#868686",
    opacity: 0.8
});

const getDocumentCardDetailsStyles = (): IDocumentCardDetailsProps["styles"] => ({
    root: {
        padding: "10px 10px 10px 10px",
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
});

interface UploadCardsProps {
    onUploadCardClick: (typeSrc: string) => void;
}

const UploadCards: React.FC<UploadCardsProps> = ({ onUploadCardClick }) => {
    return (
        <Stack horizontal wrap tokens={stackTokens} styles={{ root: { justifyContent: "center", width: "100vw" } }}>
            {uploadOptions.map((option, index) => (
                <DocumentCard
                    key={index}
                    id={`upldCrd_${option.type.toString()}`}
                    type={DocumentCardType.compact}
                    onClick={() => {
                        document.getElementById(`hidB_${option.type}`)?.click();
                    }}
                    className={cardClass}
                    style={{ color: option.gradient }}
                    styles={{
                        root: {
                            marginBottom: 0,
                            marginTop: 20,
                            borderRadius: 10,
                            padding: "10px 20px 10px 20px",
                            position: "relative",
                            opacity: 0.9,
                            selectors: {
                                ":hover": {
                                    transform: "translateY(-3px)",
                                    transition: "transform 0.2s ease-in-out"
                                },
                                ":active": {
                                    transform: "translateY(-3px) scale(0.99)",
                                    transition: "transform 0.01s ease-in-out"
                                },
                                ":hover:after": {
                                    border: "none"
                                }
                            }
                        }
                    }}
                >
                    <DocumentCardDetails styles={getDocumentCardDetailsStyles()}>
                        <div className={iconClass}>
                            {option.type === "pdf" && <FaFilePdf size={window.innerWidth > 800 ? 30 : 15} color={option.gradient} />}
                            {option.type === "youtube" && <FaYoutube size={window.innerWidth > 800 ? 40 : 18} />}
                            {option.type === "web" && <LiaPagerSolid size={window.innerWidth > 800 ? 40 : 18} />}
                        </div>

                        <DocumentCardTitle title={option.title} className={titleClass} />
                        {window.innerWidth > 800 ? <Text className={subtitleClass}>{option.subtitle}</Text> : null}
                    </DocumentCardDetails>
                </DocumentCard>
            ))}
        </Stack>
    );
};

export default UploadCards;
