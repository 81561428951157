import { useEffect, useContext, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import styles from "../ViewerPDF/ViewerPDF.module.css";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)

//import PDFExtractor from "../PDFExtractor/PDFExtractor";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let timeoutId: number;
interface LoadSuccess {
    pdf: PDFDocumentProxy;
}
interface TextItem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}

interface PDFBoxDetails {
    base64String: string;
    handleTimeout: () => void;
    handleScroll: (event: any) => void;
    setPages: (numPages: number) => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    startPage: number;
}

interface PDFPageDetails {
    handleTimeout: () => void;
    setContentList: (contentList: { title: string; dest: number }[]) => void;
    nums: number;
    pdf: PDFDocumentProxy;
    startPage: number;
}

const preparePdfPage = ({ handleTimeout, nums, setContentList, pdf, startPage }: PDFPageDetails) => {
    const pages = [];
    for (let i = 0; i < nums; i++) {
        pages.push(
            <Page
                key={i + 1}
                pageNumber={i + 1}
                width={800}
                //onPageChange={(page: any) => {
                //    console.log("Page Change!, ", page);
                //}}
                customTextRenderer={({ pageIndex, pageNumber, itemIndex, str }: TextItem) => {
                    clearTimeout(timeoutId);
                    timeoutId = setTimeout(handleTimeout, 50) as unknown as number;
                    let strKey = `${pageIndex} ${itemIndex}`;
                    if (!window.globalTextMap.has(strKey)) {
                        window.globalTextMap.set(strKey, true);
                        let strRep = str.replace(/[^a-zA-Z0-9\u0400-\u04FF]/g, "");
                        let strRepAudio = str;
                        window.globalTextArray.push({ pageIndex: pageIndex, pageNumber: pageNumber, itemIndex: itemIndex, str: strRep });
                        window.globalTextArrayAudio.push({ pageIndex: pageIndex, pageNumber: pageNumber, itemIndex: itemIndex, str: strRepAudio });
                    }
                    return `<span id='CSP_${pageIndex}_${itemIndex}' class='pdf_itm_spans pdf_itm_${pageIndex}'>${str}</span>`;
                }}
                onRenderSuccess={(pdfPage: PDFPageProxy) => {
                    if (pdfPage.pageNumber == startPage) {
                        var pageElement = document.querySelector(`[data-page-number="${startPage}"`);
                        pageElement?.scrollIntoView({ behavior: "smooth" });
                    }

                    //console.log("Page Rendered: ", pdfPage.pageNumber);
                    /*
                    window.globalPDFPageMap.set(pdfPage._pageInfo.ref.num, pdfPage._pageIndex + 1);
                    //console.log("console.log(window.globalPDFPageMap);", window.globalPDFPageMap);
                    const contentList: { title: string; dest: number }[] = [];
                    pdfPage.getTextContent().then((textContent: any) => {
                        pdfPage.getAnnotations().then(annotations => {
                            annotations.forEach((annotation: any) => {
                                if (annotation.subtype === "Link" && annotation.dest) {
                                    // Find the text associated with the link
                                    const linkText = findLinkText(annotation, textContent.items);

                                    const pageRef = annotation.dest[0].num;
                                    window.globalPDFDog = pdf;
                                    window.globalPDFContent.push({
                                        title: linkText,
                                        dest: pageRef,
                                        //url: null,
                                        rect: annotation
                                    });
                                }
                            });
                        });
                    });
                    */
                }}
            />
        );
    }
    //console.log("FFII");
    return pages;
};
const findLinkText = (annotation: any, textItems: any[]): string => {
    const rect = annotation.rect;
    const linkTextItems = textItems.filter((textItem: any) => {
        const x = textItem.transform[4];
        const y = textItem.transform[5];
        return x >= rect[0] && x <= rect[2] && y >= rect[1] && y <= rect[3];
    });

    // Concatenate the text from all matched text items
    const linkText = linkTextItems.map((item: any) => item.str.trim()).join(" ");
    return linkText ? linkText : "Link";
};
const prepareThumb = (nums: number) => {
    const pages = [];
    for (let i = 0; i < nums; i++) {
        pages.push(
            <div key={`thd_${i + 1}`}>
                <span>{i + 1}</span>
                <Page
                    scale={1}
                    key={`thumbnail_${i + 1}`}
                    pageNumber={i + 1}
                    width={100}
                    //onClick={() => setPageNumber(i + 1)}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                />
            </div>
        );
    }
    return pages;
};

const PDFBox = ({ base64String, handleTimeout, handleScroll, setPages, setContentList, startPage }: PDFBoxDetails) => {
    const [pgs, setPgs] = useState<React.ReactElement<any, any>[]>([]);
    const [rfs, setRfs] = useState<React.ReactElement<any, any>[]>([]);
    const [userPhoto, setUserPhoto] = useState("");
    const handleLoadSuccess = async (pdfObject: PDFDocumentProxy) => {
        const numPages = pdfObject.numPages;
        window.globalNumPages = numPages;
        console.log("PDF!: ", pdfObject);

        setPgs(preparePdfPage({ handleTimeout, nums: numPages, setContentList, pdf: pdfObject, startPage }));
        setRfs(prepareThumb(numPages));
        setPages(numPages);
    };
    return (
        <div id="pdf-viewer">
            <Document
                //style={{ height: "100vh", overflowY: "scroll" }}
                file={base64String}
                onLoadSuccess={handleLoadSuccess}
                loading={<div className={styles.loadingText}>Preparing PDF File</div>}
                noData={<div className={styles.loadingText}>Loading PDF File</div>}
                className={styles.pdfViewerMainWohooo}
                //renderMode="priority-page"
                //priorityPage={startPage}
            >
                <div className={styles.pdfDocContainer}>
                    <div className={styles.pdfMain} id="pdfmainid9" onScroll={handleScroll}>
                        {pgs}
                    </div>
                </div>
            </Document>
        </div>
    );
};

export default PDFBox;
