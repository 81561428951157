import React, { useState } from "react";
import "./AISearchJsonGrid.css";
import { fontFace } from "@fluentui/react";

interface AISearchJsonGridProps {
    value?: any;
    itmTitle?: string;
}

const AISearchJsonGrid: React.FC<AISearchJsonGridProps> = ({ value, itmTitle }) => {
    const [gridData, setGridData] = useState(value || {});
    let itmOrd = [
        "invoice_date",
        "contractor_name",
        "contractor_address",
        "contractor_vat_identification",
        "iban",
        "customer_name",
        "customer_address",
        "customer_vat_identification",
        "invoice_total",
        "vat",
        "invoice_currency",
        "item_number",
        "description",
        "item_code",
        "item_measure",
        "quantity",
        "unit_price",
        "total_price",
        "invoice_items"
    ];
    const sortObjectByKeyOrder = (obj: any, keyOrder: string[]) => {
        return Object.fromEntries(
            Object.entries(obj).sort(([a], [b]) => {
                return keyOrder.indexOf(a) - keyOrder.indexOf(b);
            })
        );
    };
    console.log("AZF¦", gridData);

    const renderGrid = (data: any, level = 0) => {
        return Object.entries(data).map(([key, value]) => (
            <div key={key} className={`grid-row level-${level}`}>
                <div
                    className="grid-cell key"
                    style={{
                        font: "Urbanist, Roboto",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        width: "230px"
                    }}
                >
                    {key.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}
                </div>
                <div className="grid-cell value">
                    {typeof value === "object" && value !== null ? (
                        Array.isArray(value) ? (
                            <div
                                className="array-container"
                                style={{
                                    font: "Urbanist, Roboto",
                                    paddingTop: "5px",
                                    paddingBottom: "5px"
                                }}
                            >
                                {value.map((item, index) => (
                                    <div
                                        key={index}
                                        className="array-item"
                                        style={{
                                            font: "Urbanist, Roboto",
                                            marginTop: "8px",
                                            marginBottom: "8px",
                                            borderLeft: "5px solid #f07e11",
                                            borderTop: "1px solid #f07e11"
                                        }}
                                    >
                                        {renderGrid(item, level + 1)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            renderGrid(value, level + 1)
                        )
                    ) : (
                        String(value)
                    )}
                </div>
            </div>
        ));
    };

    return (
        <div
            style={{
                font: "Urbanist, Roboto",
                padding: "5px",
                borderRadius: "5px",
                margin: "5px"
            }}
        >
            <div
                style={{
                    font: "Urbanist, Roboto",
                    fontSize: "18px",
                    fontWeight: "600",
                    padding: "3px",
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%"
                }}
            >
                {itmTitle}
            </div>

            {gridData && (
                <div style={{ font: "Urbanist, Roboto" }} className="json-grid">
                    {renderGrid(gridData?.value)}
                </div>
            )}
        </div>
    );
};

export default AISearchJsonGrid;
