import React, { useEffect, useState } from "react";
import { Modal, TextField, PrimaryButton, Pivot, PivotItem, fontFace, Label, FontSizes } from "@fluentui/react";
import { ITag } from "@fluentui/react/lib/Pickers";
import styles from "../../pages/chat/Chat.module.css";
import UserTagPicker from "../../components/UserTagPicker/UserTagPicker"; // Adjust the path to your UserTagPicker component
import AssistantTypeCards from "../../components/AssistantTypeCards/AssistantTypeCards"; // Adjust the path to your UserTagPicker component

import { toast, ToastContainer, Bounce } from "react-toastify";

import { TailoredCards } from "../../components/TailoredCards";

import { gnewgroup, guser } from "../../interfaces";
import { set } from "lodash";

interface NewSpaceModalProps {
    isSpaceModalOpen: boolean;
    closeSpacesPanel: () => void;
    isNewSpaceInfoBip: boolean;
    user: guser;
    addSpace: (newGroup: gnewgroup) => void;
}
const textFieldStyles = {
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        selectors: {
            "::after": {
                borderRadius: "5px "
                //border: "2px solid #404040"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};
const AddSpaceModal: React.FC<NewSpaceModalProps> = ({ isSpaceModalOpen, closeSpacesPanel, isNewSpaceInfoBip, user, addSpace }) => {
    const [newSpace, setNewSpace] = useState<gnewgroup>({
        id: "",
        groupname: "",
        description: "",
        scrt: "",
        appid: "",
        specializedSettings: {}
    });
    const genRandom = (length: number) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const handleAddSpace = (specializedSettings?: any) => {
        let groupid = `${newSpace?.groupname}_${user.userId}_${genRandom(5)}`;
        if (isNewSpaceInfoBip == true) {
            groupid = newSpace.id;
        }
        setNewSpace({ ...newSpace, id: groupid });

        if (newSpace.groupname == "") {
            toast.error("Please enter an Assistant name");
            return;
        }
        if (isNewSpaceInfoBip == true && newSpace.id === "") {
            toast.error("Please enter an InfoBip ID");
            return;
        }
        addSpace({
            id: groupid,
            groupname: newSpace.groupname,
            description: newSpace.description,
            appid: newSpace.appid,
            scrt: newSpace.scrt,
            specializedSettings: specializedSettings
        });
        closeSpacesPanel();
    };
    const handleChange = (field: keyof gnewgroup, value: string | number | boolean) => {
        setNewSpace(prev => ({ ...prev, [field]: value }));
    };
    const onStateChange = (state: string) => {};
    useEffect(() => {
        setNewSpace({
            id: "",
            groupname: "",
            description: "",
            scrt: "",
            appid: "",
            specializedSettings: {}
        });
    }, [isSpaceModalOpen]);
    return (
        <Modal
            isOpen={isSpaceModalOpen}
            onDismiss={closeSpacesPanel}
            isBlocking={false}
            containerClassName={styles.spaceAddModal}
            styles={{
                scrollableContent: {
                    width: "100%",

                    minHeight: "200px",
                    display: "flex",
                    flexDirection: "column"
                },
                main: {
                    maxHeight: "100vh",
                    minHeight: "200px"
                }
            }}
        >
            <div>
                <h3 className={styles.modalTitle}>New Assistant</h3>

                <TextField
                    styles={textFieldStyles}
                    label="Space Name"
                    defaultValue={newSpace.groupname}
                    onChange={(event, newValue) => handleChange("groupname", newValue || "")}
                    className={styles.spaceTextFields}
                />
                {isNewSpaceInfoBip && (
                    <TextField
                        styles={textFieldStyles}
                        defaultValue={newSpace.id}
                        label="InfoBip ID"
                        onChange={(event, newValue) => handleChange("id", newValue || "")}
                        className={styles.spaceTextFields}
                    />
                )}
                <TextField
                    styles={textFieldStyles}
                    className={styles.spaceTextFields}
                    defaultValue={newSpace.description}
                    label="Space Description"
                    onChange={(event, newValue) => handleChange("description", newValue || "")}
                />

                {isNewSpaceInfoBip && (
                    <TextField
                        styles={textFieldStyles}
                        type="password"
                        label="Bot ID"
                        value={newSpace.appid}
                        onChange={(event, newValue) => handleChange("appid", newValue || "")}
                    />
                )}
                {isNewSpaceInfoBip && (
                    <TextField
                        styles={textFieldStyles}
                        type="password"
                        label="App Secret"
                        value={newSpace.scrt}
                        onChange={(event, newValue) => handleChange("scrt", newValue || "")}
                    />
                )}
                {/* <TailoredCards onStateChange={onStateChange}></TailoredCards> */}
                <h4 className={styles.modalSub}>Purpose</h4>
                <div className={styles.assitantCardsContainer}>
                    <AssistantTypeCards addSpace={handleAddSpace} />
                </div>
                <PrimaryButton text="Or Create Blank Assistant" className={styles.spacePicker} onClick={handleAddSpace} />
            </div>
        </Modal>
    );
};

export default AddSpaceModal;
