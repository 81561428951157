import { useCallback, useState, useEffect, useContext } from "react";
//import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import React from "react";
import "./ViewerPDF.module.css";
import styles from "./ViewerPDF.module.css";

import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import crypto from "crypto";
import { IconButton } from "@fluentui/react";
import PageSelector from "../PageSelector/PageSelector";
import { GlobalContext } from "../../GlobalContext";

interface TextItem {
    pageIndex: number;
    pageNumber: number;
    itemIndex: number;
    str: string;
}
import PDFBox from "../PDFBox/PDFBox";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { gcontentlist, gpagemap } from "../../interfaces";
/*
const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/"
};
*/
const excl = ["internal use", "p a g e", "page", "internaluse"];
interface ViewerPDFProps {
    defaultFile: any;
    startPage: string;
    searchString: string;
    contentlist: gcontentlist[] | [];
    pagemap: gpagemap;
    companyId: string;
}
let timeoutId: number;

const ViewerPDF = ({ defaultFile, startPage, searchString, contentlist, pagemap, companyId }: ViewerPDFProps) => {
    const [file, setFile] = useState(defaultFile);
    const [base64String, setBase64String] = useState("");
    const [numPages, setNumPages] = useState<number>();
    const [totalPages, setTotalPages] = useState<number>();
    const [options, setOptions] = useState({});

    const [currentPage, setCurrentPage] = useState(0);
    const [referencePage, setReferencePage] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [textArray, setTextArray] = useState<TextItem[]>([]);
    const [docLoaded, setDocLoaded] = useState(false);
    const [hMatches, setHMatches] = useState<{ itemIndex: number; pageIndex: number; pageNumber: number; str: string }[][]>([]);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [linkData, setLinkData] = useState<Array<gcontentlist>>([]);
    const [contentPages, setContentPages] = useState<{ [key: string]: number }>(); // Add this line

    const [activeContentListId, setActiveContentListId] = useState<number>();
    const { sourceHighlighting } = useContext(GlobalContext);
    const fetchBase64 = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: "blob" // Change responseType to "blob"
            });
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => (reader.result ? resolve(reader.result.toString().split(",")[1]) : ""); // Get Base64 part
                reader.onerror = reject;
                reader.readAsDataURL(response.data); // Read the blob as Data URL
            });
        } catch (error) {
            console.error("Error fetching the PDF:", error);
            return null;
        }
    };

    const scrollToPage = (pageNumber: number) => {
        //console.log("ScrollToPage", pageNumber);
        var pageElement = document.querySelector(`[data-page-number="${pageNumber}"`);
        pageElement?.scrollIntoView({ behavior: "smooth" });
        return true;
    };

    const fetchSpeechAudio = async (page: number) => {
        const tokenUrl = "https://westeurope.api.cognitive.microsoft.com/sts/v1.0/issueToken";
        const ttsUrl = "https://westeurope.tts.speech.microsoft.com/cognitiveservices/v1";
        setCurrentPage(page);

        const text = window.globalTextArrayAudio
            .filter(
                item =>
                    item.pageNumber === page &&
                    !/^\d+$/.test(item.str) && // Exclude if string is only digits
                    !excl.includes(item.str.toLowerCase().trim()) && // Exclude if string is in the exclusion array
                    item.str.trim() !== "|"
                // && /[a-zA-Z\u0400-\u04FF]/.test(item.str) // Include if string contains at least one letter
            )
            .sort((a, b) => {
                if (a.pageIndex !== b.pageIndex) {
                    return a.pageIndex - b.pageIndex;
                }
                return a.itemIndex - b.itemIndex;
            })
            .map(item => item.str)
            .join(" ");
        //console.log("PS¦Text", text);

        if (text && text.trim().length > 0) {
            try {
                const tokenResponse = await axios.post(
                    tokenUrl,
                    {},
                    {
                        headers: {
                            "Ocp-Apim-Subscription-Key": "91c9d27ba50e483783a44465c41f24da",
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                );
                const token = tokenResponse.data;
                const speechResponse = await axios.post(
                    ttsUrl,
                    `<speak version='1.0' xml:lang='bg-BG'><voice xml:lang='bg-BG' xml:gender='Female' name='bg-BG-KalinaNeural'>${text}</voice></speak>`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/ssml+xml",
                            "X-Microsoft-OutputFormat": "audio-16khz-128kbitrate-mono-mp3"
                        },
                        responseType: "blob"
                    }
                );
                const audioUrl = window.URL.createObjectURL(speechResponse.data);
                const newAudio = new Audio(audioUrl);
                newAudio.loop = false;

                newAudio.onended = () => {
                    //console.log("FSA¦Advance Page", numPages, page, page + 1);
                    if (numPages && page + 1 < numPages) {
                        scrollToPage(page + 1);
                        fetchSpeechAudio(page + 1);
                    }
                };
                setAudio(newAudio);
                newAudio.play();
                setIsPlaying(true);
            } catch (error) {
                console.error("Error fetching or playing speech:", error);
            }
        } else {
            console.log("Advance Page No Content", page, numPages);
            if (numPages && currentPage < numPages) {
                scrollToPage(page + 1);
                fetchSpeechAudio(page + 1);
            }
        }
    };
    const playSpeech = () => {
        console.log("PS¦playSpeech", currentPage);
        fetchSpeechAudio(currentPage);
    };
    const stopSpeech = () => {
        if (audio) {
            audio.pause();
            setIsPlaying(false);
        }
    };
    const handlePageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentPage(Number(event.target.value));
        scrollToPage(Number(event.target.value));
    };
    useEffect(() => {
        setLinkData(contentlist);
        setContentPages(pagemap);

        setCurrentPage(parseInt(startPage));
        setReferencePage(parseInt(startPage));
        fetchBase64(`/content/${companyId}/${file}`).then(base64 => {
            setBase64String(`data:application/pdf;base64,${base64}`);
        });
    }, [file]);

    const setPages = (numP: number): void => {
        console.log("SetPages", numP);
        setNumPages(numP);
        setTotalPages(numP);
        window.globalTextArray = [];
        window.globalTextArrayAudio = [];
        window.globalTextMap = new Map<string, boolean>();
        window.globalPDFExtr = [];
        window.globalPDFPageMap = new Map<number, any>();
        window.globalPDFContent = [];
    };
    const handlePageRenderSuccess = () => {
        console.log("Page Rendered");
    };

    const findConsecutiveMatchesFunc = (searchString: string) => {
        searchString = searchString.replace(/[^a-zA-Z0-9\u0400-\u04FF]/g, "");
        const results = [];
        // Loop through the array, stopping where a full comparison is still possible
        for (let i = 0; i < window.globalTextArray.length; i++) {
            let combinedStr = "";
            let j = i;
            // Keep concatenating while within bounds and the combinedStr is not longer than searchString
            while (j < window.globalTextArray.length && combinedStr.length < searchString.length) {
                combinedStr += window.globalTextArray[j].str;
                //console.log(combinedStr)
                j++;
                // Check if the current combined string matches the search string
                if (combinedStr.startsWith(searchString)) {
                    // If a match is found, slice the array from start index i to current index j
                    results.push(window.globalTextArray.slice(i, j));
                    break; // Optionally break if only the first match is needed
                }
            }
        }
        return results;
    };
    const applyFontColorToMatches = (matches: { itemIndex: number; pageIndex: number; pageNumber: number; str: string }[][], color: string): void => {
        matches.forEach(group => {
            group.forEach(item => {
                // Construct the ID in the format 'CSP_{pageIndex}_{itemIndex}'
                const elementId = `CSP_${item.pageIndex}_${item.itemIndex}`;
                const element = document.getElementById(elementId);

                // Apply the font color if the element is found
                if (element) {
                    element.style.backgroundColor = "rgba(67, 67, 67, 0.131)";
                    element.style.color = "rgba(0, 0, 0, 0)";
                }
            });
        });
    };
    function filterByPageNumber(data: { itemIndex: number; pageIndex: number; pageNumber: number; str: string }[][], pageNumber: number) {
        return data.map(subArray => subArray.filter(item => item.pageNumber >= pageNumber)).filter(subArray => subArray.length > 0);
    }
    const setHighlights = async () => {
        //scrollToPage(parseInt(startPage));

        //console.log("PDFVWR¦globalTextMap", window.globalTextMap.size);
        if (docLoaded === false) {
            //console.log("PDFVWR¦SHHHH", window.globalTextArray);
            setDocLoaded(true);
            window.globalTextArray = window.globalTextArray.sort((a, b) => {
                if (a.pageIndex !== b.pageIndex) {
                    return a.pageIndex - b.pageIndex; // Primary sort by pageIndex
                }
                return a.itemIndex - b.itemIndex; // Secondary sort by itemIndex if pageIndex is the same
            });

            const highlightMatches = findConsecutiveMatchesFunc(searchString);
            setHMatches(highlightMatches);
            applyFontColorToMatches(highlightMatches, "yellow");

            //console.log("PDFVWR¦HighlightMatches", highlightMatches);

            const matchesAboveScrl = filterByPageNumber(highlightMatches, parseInt(startPage));
            //console.log("PDFVWR¦mathesAboveScrl", matchesAboveScrl);

            if (matchesAboveScrl.length > 0) {
                if (matchesAboveScrl[0][0].pageNumber > parseInt(startPage)) {
                    setCurrentPage(matchesAboveScrl[0][0].pageNumber); // The Current Focused Page
                    setReferencePage(matchesAboveScrl[0][0].pageNumber); // The Page Initially Targeted At Start
                    scrollToPage(matchesAboveScrl[0][0].pageNumber);
                } else {
                    scrollToPage(parseInt(startPage));
                }
            } else {
                scrollToPage(parseInt(startPage));
            }
        } else {
            //console.log("PDFVWR¦hMatches", hMatches);
            applyFontColorToMatches(hMatches, "red");
        }
    };
    // Define the function to call after the timeout
    const handleTimeout = () => {
        //console.log("customTextRenderer", searchString);
        if (searchString && searchString.length > 0 && sourceHighlighting == true) {
            setHighlights();
        }
    };
    const setContentList = (contentList: { title: string; dest: number }[]) => {
        console.log("VWRPDF¦setContentList", contentList);
    };
    const handleScroll = (event: any) => {
        let { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        let pageHeight = (scrollHeight ? scrollHeight : 1) / (numPages ? numPages : 1);
        let pp = Math.ceil((scrollTop + clientHeight - clientHeight / 3) / pageHeight);
        setCurrentPage(pp);
    };

    function contentListValueNumbering(item: gcontentlist) {
        const currentValue = item.destPageNum;

        if (item.srcText) {
            let splitValues = item.srcText.trim().replace(" .", ".").split(" ");

            return {
                currentValue: currentValue,
                splitValues: splitValues,
                splitLength: splitValues.length,
                itemLevel: (splitValues[0].match(/\./g) || []).length
            };
        } else {
            return {
                currentValue: "",
                splitValues: [],
                splitLength: 0,
                itemLevel: false
            };
        }
    }

    const handleClick = (target: EventTarget, itemDest: number, indx: number) => {
        setActiveContentListId(indx);

        scrollToPage(itemDest);
        {
            /*
    window.globalPDFDog.getPage(1).then((pdfPage: PDFPageProxy) => {
            // Assuming you have a pdfPage object already fetched
            const viewportDefalt = pdfPage.getViewport({ scale: 1 });
            const currentViewerElement = document.querySelector("#pdf-viewer"); // Adjust the selector as needed
            if (!currentViewerElement) return;
            const currentScale = currentViewerElement.clientWidth / viewportDefalt.width;

            // Get the new viewport with the effective scale
            const viewport = pdfPage.getViewport({ scale: currentScale });

            const scaleY = window.innerHeight / viewport.height; // Scale factor for entire page to fit vertically in viewer
            const scaleX = window.innerWidth / viewport.width; // Scale factor for entire page to fit horizontally in viewer

            const scaledX = destHorizontal * scaleX; // Calculated x position in viewer's scale
            const scaledY = destVertical * scaleY; // Calculated y position in viewer's scale
            const scaledZoom = zoom * Math.min(scaleX, scaleY); // Adjust zoom based on smaller of horizontal/vertical scale

            // Implement scrolling or setting viewport
            // This is just a hypothetical function, implementation depends on your setup
            scrollToPage(destPage);
        });
    */
        }
    };
    function renderLinkData() {
        let arr: any = [];
        let weAreOver = true;
        let weAreOverRef = true;
        let setActive = false;
        let setActiveRef = false;
        const rndrlmnt: JSX.Element[] = [];

        linkData.map((item, index) => {
            if (item.srcText !== "") {
                const { currentValue, splitValues, splitLength, itemLevel } = contentListValueNumbering(item);

                if (weAreOver != currentPage > item.destPageNum) {
                    setActive = true;
                }
                weAreOver = currentPage > item.destPageNum;
                if (weAreOverRef != referencePage > item.destPageNum) {
                    setActiveRef = true;
                }
                weAreOverRef = referencePage > item.destPageNum;
                if (itemLevel === 0) {
                    rndrlmnt.push(
                        <ul key={index}>
                            <div
                                key={index}
                                className={`${styles.pdfContListNormal} ${setActive == true ? styles.pdfContListSelected : ""} ${
                                    setActiveRef == true ? styles.pdfContListBold : ""
                                }
                                `}
                            >
                                <div onClick={event => handleClick(event.target, item.destPageNum, index)} className={styles.contentItem}>
                                    {item.srcText.replace(/\.{3,}/g, "")}
                                </div>
                            </div>
                        </ul>
                    );
                } else {
                    rndrlmnt.push(
                        <ul key={index}>
                            <div
                                key={index}
                                className={`${styles.pdfContListNormal} ${setActive == true ? styles.pdfContListSelected : ""} ${
                                    setActiveRef == true ? styles.pdfContListBold : ""
                                }
                                `}
                            >
                                <div onClick={event => handleClick(event.target, item.destPageNum, index)} className={styles.contentItem}>
                                    <div className={styles.contentItemNumbeing}>{splitValues[0]}</div>
                                    <div className={styles.contentItemText}>{splitValues.slice(1).join(" ")}</div>
                                </div>
                            </div>
                        </ul>
                    );
                }
                if (setActive == true) {
                    setActive = false;
                }
                if (setActiveRef == true) {
                    setActiveRef = false;
                }
            }
        });

        return rndrlmnt;
    }
    return (
        <div className={styles.Example}>
            <header style={{ height: "50px" }}>
                <div style={{ fontSize: "15px", top: "-15px" }}>
                    <span>{file.split("_fid=")[0]}</span>
                </div>
            </header>
            <div className="Example__container" style={{ position: "sticky", top: 0, zIndex: 999, minWidth: "500px" }}>
                <div className={styles.volButs}>
                    <IconButton
                        onClick={playSpeech}
                        disabled={isPlaying}
                        iconProps={{ iconName: "Volume3" }}
                        title="Speak Page"
                        styles={{
                            root: {
                                backgroundColor: "#FFFFFF",
                                margin: "5px 5px"
                            }
                        }}
                    >
                        Speak Page
                    </IconButton>
                    <IconButton
                        onClick={stopSpeech}
                        disabled={!isPlaying}
                        iconProps={{ iconName: "Volume0" }}
                        title="Stop Speech"
                        styles={{
                            root: {
                                backgroundColor: "#FFFFFF",
                                margin: "5px 5px"
                            }
                        }}
                    >
                        Stop Speech
                    </IconButton>
                </div>

                <div>
                    <PageSelector currentPage={currentPage} numPages={totalPages ? totalPages : 0} handlePageChange={handlePageChange}></PageSelector>
                </div>
            </div>

            <div style={{ display: "flex" }}>
                {linkData.length > 0 ? (
                    <div className={styles.pdfContentsC} style={{}}>
                        {renderLinkData()}
                    </div>
                ) : null}

                <div className="pdfViewC" style={{ flex: 1 }}>
                    <PDFBox
                        base64String={base64String}
                        handleTimeout={handleTimeout}
                        handleScroll={handleScroll}
                        setPages={setPages}
                        setContentList={setContentList}
                        startPage={referencePage}
                    ></PDFBox>
                </div>
            </div>
        </div>
    );
};

export default ViewerPDF;
