import React, { useState, useEffect, useContext } from "react";
import {
    Stack,
    TextField,
    TooltipHost,
    Checkbox,
    PrimaryButton,
    ITextFieldStyles,
    IStackStyles,
    ICheckboxStyles,
    ISliderStyles,
    DirectionalHint,
    IconButton,
    Slider,
    IDropdownOption,
    Dropdown,
    IDropdownStyles,
    ITextFieldProps,
    IRenderFunction
} from "@fluentui/react";
import { toast } from "react-toastify";
import styles from "./SpaceEditForm.module.css";

import { gselectedgroup } from "../../interfaces";
import { GlobalContext } from "../../GlobalContext"; // Tests¦Context Accross Routes (ex. Chat/Search)

interface ISpaceEditFormProps {
    selectedGroup: gselectedgroup;
    onEditSpaceSave: () => void;
    defaultPromptTemplateAsInPythonBackend: string;
    defaultGreetingsMessage: string;
    onGroupDel: () => void;
}

const SpaceEditForm: React.FC<ISpaceEditFormProps> = ({
    selectedGroup,
    onEditSpaceSave,
    defaultPromptTemplateAsInPythonBackend,
    defaultGreetingsMessage,
    onGroupDel
}) => {
    console.log("SpaceEditForm¦", selectedGroup);
    const { loggedInUser, activeComp } = useContext(GlobalContext);
    const [editedSpace, setEditedSpace] = useState<gselectedgroup>({ ...selectedGroup });
    const [isLoadingAPI, setIsLoadingAPI] = useState(false);

    const [shareURL, setShareURL] = useState("");
    const [anonSharelink, setAnonSharelink] = useState(false);
    const chatbotScript = `<script src="https://miscst.blob.core.windows.net/misc/kognify.js"></script>
    <script>
        initChatbot({
            chatBotID: "${encodeURIComponent(selectedGroup.selectionId)}",
            iconSrc: "https://miscst.blob.core.windows.net/misc/kognifyLogo.png",
            width: 600,
            height: 800
        });
    </script>`;
    const chatbotEmbed = `<iframe
    src="https://app.kognify.ai/#/${activeComp.companyid}/${encodeURIComponent(selectedGroup.selectionId)}/chat?ifrm=true"
    style="width: 800px; height: 800px; border: none; z-index: 1000;"allowtransparency="true">
</iframe>`;
    useEffect(() => {
        setEditedSpace({ ...selectedGroup });
        handleShareURL(anonSharelink);
    }, [selectedGroup]);

    useEffect(() => {
        setIsLoadingAPI(false);
    }, []);

    const getHeader = () => {
        return {
            ...(loggedInUser?.token ? { Authorization: `Bearer ${loggedInUser.token}` } : {}),
            "X-MS-CLIENT-PRINCIPAL-NAME": loggedInUser?.mail ? loggedInUser.mail : "",
            "X-MS-CLIENT-PRINCIPAL-ID": loggedInUser?.userId ? loggedInUser.userId : "",
            "Content-Type": "application/json"
        };
    };

    const textFieldStyles: Partial<ITextFieldStyles> = {
        wrapper: {
            selectors: {
                label: {
                    fontFamily: "Urbanist"
                }
            }
        },
        root: {
            borderRadius: "5px"
        },
        fieldGroup: {
            width: "90vw",
            maxWidth: "800px",
            borderRadius: "5px",
            selectors: {
                "::after": {
                    borderRadius: "5px ",
                    border: "2px solid #404040"
                }
            }
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "14px",
                    fontFamily: "Urbanist"
                }
            }
        }
    };
    const textFieldStylesMultiline: Partial<ITextFieldStyles> = {
        wrapper: {
            selectors: {
                label: {
                    fontFamily: "Urbanist"
                }
            }
        },
        root: {
            borderRadius: "8px"
        },
        fieldGroup: {
            width: "90vw",
            maxWidth: "800px",
            height: 100,
            borderRadius: "8px",
            selectors: {
                "::after": {
                    borderRadius: "8px ",
                    border: "2px solid #333"
                }
            }
        },
        subComponentStyles: {
            label: {
                root: {
                    fontSize: "14px",
                    fontFamily: "Urbanist"
                }
            }
        }
    };

    const checkBoxStyles: Partial<ICheckboxStyles> = {
        text: {
            fontFamily: "Urbanist"
        },
        checkbox: {
            background: "white",
            border: "1px solid #333",
            color: "white",
            borderColor: "#333", // Border color when unchecked
            selectors: {
                ":after": {
                    content: '""',
                    borderColor: "#333",
                    background: "#333",
                    color: "#333"
                },
                ":hover": {
                    border: "3px solid #333"
                },
                ":focus": {
                    border: "3px solid #333"
                },
                ":active": {
                    border: "3px solid #333"
                }
            }
        },
        checkmark: {
            color: "white", // Color of the checkmark
            background: "#333",
            border: "3px solid #333",
            selectors: {
                ":hover": {
                    color: "white", // Hover checkmark color
                    border: "3px solid white"
                }
            }
        },
        root: {
            fontFamily: "Urbanist",
            selectors: {
                ":hover .ms-Checkbox-checkbox": {
                    background: "#7e7e7e",
                    border: "3px solid #7e7e7e"
                },
                ":hover .ms-Checkbox-checkmark": {
                    background: "#7e7e7e", // Hover checkmark color
                    border: "1px solid #7e7e7e"
                },
                ".is-checked .ms-Checkbox-checkbox": {
                    background: "#7e7e7e",
                    border: "3px solid #7e7e7e"
                }
            }
        }
    };
    const stackStyles: Partial<IStackStyles> = {
        root: { gap: 10 }
    };
    const tooltipStyles = {
        styles: {
            root: {
                borderRadius: "5px"
            },
            beak: {
                left: "20px !important",
                right: "auto !important"
            },
            beakCurtain: {
                borderRadius: "5px"
            },
            calloutMain: {
                borderRadius: "5px"
            }
        }
    };

    const sliderStyles: Partial<ISliderStyles> = {
        titleLabel: {
            fontFamily: "Urbanist"
        },
        valueLabel: {
            fontFamily: "Urbanist"
        },
        slideBox: {
            borderRadius: "5px",
            color: "#333",
            borderColor: "#333",

            selectors: {
                ":hover": {
                    color: "#333"
                },
                ":active": {
                    color: "#333"
                },
                ":hover .ms-Slider-active": {
                    background: "rgb(241, 100, 30) !important"
                },
                ":hover .ms-Slider-thumb": {
                    borderColor: "orange !important",
                    background: "white !important"
                }
            }
        },

        thumb: {
            selectors: {
                borderColor: "orange !important",
                backrgoundColor: "orange",
                color: "orange",
                ":hover": {
                    borderColor: "orange !important",
                    backrgoundColor: "orange",
                    color: "orange"
                },
                ":active": {
                    borderColor: "orange !important"
                }
            }
        },
        line: {
            selectors: {
                ":hover": {
                    background: "orange",
                    backrgoundColor: "orange",
                    color: "orange"
                },

                ":active": {
                    color: "#333",
                    borderColor: "#333",
                    background: "#333",
                    backgroundColor: "#333"
                }
            }
        },
        lineContainer: {
            selectors: {
                ":hover": {
                    selectors: {
                        ".ms-Slider-active": {
                            background: "orange",
                            backrgoundColor: "orange",
                            borderColor: "#333",
                            color: "orange"
                        },
                        ":active": {
                            color: "#333",
                            borderColor: "#333",
                            background: "#333",
                            backgroundColor: "#333"
                        }
                    }
                }
            }
        }
    };
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            padding: "0",
            margin: "0",
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        label: {
            fontFamily: "Urbanist",
            fontSize: "15px",
            borderRadius: "5px !important",
            borderColor: "#333",
            width: "100%",
            marginBottom: "5px"
        },
        title: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            borderColor: "#333",
            fontSize: "12px",
            height: "30px",
            width: "100%"
        },

        dropdown: {
            borderRadius: "5px !important",
            color: "#333",
            borderColor: "#333",
            selectors: {
                ":focus": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":hover": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":active": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                },
                ":before": {
                    borderRadius: "5px !important",
                    borderColor: "#333 !important",
                    color: "#333"
                }
            }
        },
        dropdownItem: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",
            fontSize: "12px",
            height: "30px",
            color: "#333"
        },

        dropdownItemSelected: {
            fontFamily: "Urbanist",
            borderRadius: "5px !important",

            fontSize: "12px",
            height: "30px",
            color: "#333"
        },
        caretDown: {
            borderRadius: "5px !important",
            color: "#333"
        }
    };

    const handleChange = (field: keyof gselectedgroup, value: string | number | boolean) => {
        setEditedSpace(prev => ({ ...prev, [field]: value }));
        if (field === "sharewithcode") {
            handleShareURL(anonSharelink);
        }
    };

    const handleShareURL = (anon: boolean) => {
        let sUrl = `${window.location.origin}?redeemCode=${encodeURIComponent(selectedGroup.selectionId)}&spt=${encodeURIComponent(
            selectedGroup.selectionText
        )}`;
        if (anon) {
            sUrl = `${sUrl}&A=1`;
        }
        setShareURL(sUrl);
    };

    const handleAnonChange = (checked: boolean) => {
        setAnonSharelink(checked);
        handleShareURL(checked);
    };

    const handleSave = () => {
        setIsLoadingAPI(true);
        fetch("/qAddGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({
                groupid: editedSpace.selectionId,
                groupname: editedSpace.selectionText,
                companyid: "", // Doesnt Matter, Upsert will Ignore It
                description: editedSpace.description,
                custaiinst: editedSpace.custaiinst,
                sharewithcode: editedSpace.sharewithcode,
                custgreetingmessage: editedSpace.custgreetingmessage,
                isintegrateddata: editedSpace.isintegrateddata,
                allowyoutube: editedSpace.allowyoutube,
                allowweb: editedSpace.allowweb,
                enabledocaudio: editedSpace.enabledocaudio,
                retrievecount: editedSpace.retrievecount,
                sectionoverlap: editedSpace.sectionoverlap,
                maxsectionlength: editedSpace.maxsectionlength,
                sentencesearchlimit: editedSpace.sentencesearchlimit,
                inscope: editedSpace.inscope,
                temperature: editedSpace.temperature,
                responsetokenlimit: editedSpace.responsetokenlimit,
                disablecitationhighlighting: editedSpace.disablecitationhighlighting,
                strictness: editedSpace.strictness,
                invoicesgroup: editedSpace.invoicesgroup,
                questionsuggestions: editedSpace.questionsuggestions,
                nosidebysideview: editedSpace.nosidebysideview
            })
        }).then(() => {
            setIsLoadingAPI(false);
            onEditSpaceSave();
        });
    };
    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {})
            .catch(err => {});

        toast.success("Copied to Clipboard");
    };
    const delSpace = async () => {
        const groupid = selectedGroup.selectionId;
        fetch("/qDelGroup", {
            method: "POST",
            headers: getHeader(),
            body: JSON.stringify({ groupid: groupid })
        }).then(Response => {
            onGroupDel();
        });
    };
    const handleDelete = () => {
        const userConfirmed = window.confirm("Are you sure you want to delete this space?");
        if (userConfirmed) {
            console.log("Deleting Space");
            delSpace();
        }
    };
    const resultCountOptions: IDropdownOption[] = [
        { key: "3", text: "3 results" },
        { key: "5", text: "5 results" },
        { key: "10", text: "10 results" },
        { key: "20", text: "20 results" }
    ];

    return (
        <div className={styles.editModalWrap}>
            <Stack styles={stackStyles}>
                <div className={styles.editFormSectionTitle}>
                    <span>General Configuration</span>
                </div>
                <TextField
                    className={styles.spaceTextFieldsStack}
                    styles={textFieldStyles}
                    label="Space Name"
                    value={editedSpace.selectionText}
                    onChange={(_, newValue) => handleChange("selectionText", newValue || "")}
                />
                <TextField
                    className={styles.spaceTextFieldsStack}
                    styles={textFieldStyles}
                    label="Space Description"
                    value={editedSpace.description}
                    onChange={(_, newValue) => handleChange("description", newValue || "")}
                />
                <TooltipHost
                    content="HTML of first message sent from Kognify when new chat is started"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <TextField
                        className={styles.spaceTextFields}
                        styles={textFieldStylesMultiline}
                        label="Greeting Message"
                        multiline
                        resizable={false}
                        value={editedSpace.custgreetingmessage || defaultGreetingsMessage}
                        onChange={(_, newValue) => handleChange("custgreetingmessage", newValue || "")}
                    />
                </TooltipHost>
                <TooltipHost content="Add options to speak the content" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Enable Content Audio [Beta]"
                        disabled={false}
                        checked={editedSpace.enabledocaudio == 1}
                        onChange={(_, checked) => handleChange("enabledocaudio", checked ? 1 : 0)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Set this Space to be dedicated to Data Extraction. For now Invoice Processing and General Form Extraction are supported"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Data Extraction Space [Beta]"
                        disabled={false}
                        checked={editedSpace.invoicesgroup == 1}
                        onChange={(_, checked) => handleChange("invoicesgroup", checked ? 1 : 0)}
                    />
                </TooltipHost>

                <div className={styles.editFormSectionTitle}>
                    <span>Response Cofiguration</span>
                </div>
                <TooltipHost
                    content="Allow AI Model to use information outside of the supplied sources (General AI Chat experience)"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="General Chat Mode"
                        disabled={false}
                        checked={!(editedSpace.inscope == 1)}
                        onChange={(_, checked) => handleChange("inscope", checked ? 0 : 1)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Instructions sent to Language Model containing details how to respond"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <TextField
                        className={styles.spaceTextFields}
                        styles={textFieldStylesMultiline}
                        label="AI Instructions"
                        multiline
                        resizable={false}
                        value={editedSpace.custaiinst || defaultPromptTemplateAsInPythonBackend}
                        onChange={(_, newValue) => handleChange("custaiinst", newValue || "")}
                    />
                </TooltipHost>

                <TooltipHost
                    content="How creative the AI Model should be when generating responses (Higher is more creative)"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Slider
                        styles={sliderStyles}
                        label="AI Temperature (Creativity)"
                        min={0}
                        max={1}
                        step={0.1}
                        defaultValue={editedSpace.temperature}
                        showValue
                        onChange={value => handleChange("temperature", value)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="The amount of search results to be sent to the AI Model"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Dropdown
                        label="Search Results Count"
                        options={resultCountOptions}
                        selectedKey={editedSpace.retrievecount.toString()}
                        onChange={(_, option) => handleChange("retrievecount", Number(option?.key))}
                        styles={dropdownStyles} // If you want to apply custom styles
                    />
                </TooltipHost>
                <TooltipHost
                    content="The amount of relevance needed for a search result to be included in the final results. Higher is more strict leading to less results."
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Slider
                        styles={sliderStyles}
                        label="Search Relevance Strictness"
                        min={1}
                        max={5}
                        step={1}
                        defaultValue={editedSpace.strictness}
                        showValue
                        onChange={value => handleChange("strictness", value)}
                    />
                </TooltipHost>
                <TooltipHost content="Limit how long the responses can be" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                    <Slider
                        styles={sliderStyles}
                        label="Response Token Limit"
                        min={500}
                        max={4096}
                        step={1}
                        defaultValue={editedSpace.responsetokenlimit}
                        showValue
                        onChange={value => handleChange("responsetokenlimit", value)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Disable citations and one-click document citation navigation. This setting disables core functionality of the Web App and is intended for integrated spaces or other special cases"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Disable Citations"
                        disabled={false}
                        checked={editedSpace.disablecitationhighlighting == 1}
                        onChange={(_, checked) => handleChange("disablecitationhighlighting", checked ? 1 : 0)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="The AI Model will add followup question suggestions to the response."
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Enable Followup Question Suggestions"
                        disabled={false}
                        checked={editedSpace.questionsuggestions == 1}
                        onChange={(_, checked) => handleChange("questionsuggestions", checked ? 1 : 0)}
                    />
                </TooltipHost>
                <div className={styles.editFormSectionTitle}>
                    <span>Sharing Options</span>
                </div>

                <div className={styles.custLabel}>
                    <span>Share Space with Link</span>
                </div>
                <TooltipHost
                    content="Space will be accessible through a special link to anybody with the link. Options for link sharing will become available to the Contributors and Owners through the Sharing Icon in the Space"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Allow Space to be accessed with Code"
                        checked={editedSpace.sharewithcode}
                        onChange={(_, newValue) => handleChange("sharewithcode", newValue || "")}
                    />
                </TooltipHost>
                {editedSpace.sharewithcode == true ? (
                    <div className={styles.editSpaceShareCode}>
                        <span>
                            <div className={styles.redeemCodeBox}>
                                {shareURL}
                                <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(shareURL)}>
                                    Copy
                                </button>
                            </div>
                        </span>
                        <TooltipHost
                            content="Don't require user to signup/login to access the Space (Quest Access)"
                            directionalHint={DirectionalHint.bottomLeftEdge}
                            calloutProps={tooltipStyles}
                        >
                            <Checkbox
                                className={styles.editSpaceCheckbox}
                                styles={checkBoxStyles}
                                label="Get Anonymous Link"
                                disabled={false}
                                checked={anonSharelink}
                                onChange={(_, checked) => handleAnonChange(checked || false)}
                            />
                        </TooltipHost>
                        <br />
                    </div>
                ) : null}
                <div className={styles.custLabelMrgn}>
                    <span>Embed in Web Sites [Beta]</span>
                </div>
                <span>
                    <div className={styles.redeemCodeBox}>
                        To add Kognify as an expandable icon at the bottom right of your website page, put the generated code at the bottom of your page's HTML
                        after the closing body tag.
                        <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(chatbotScript)}>
                            Get Script Code
                        </button>
                    </div>
                </span>
                <span>
                    <div className={styles.redeemCodeBox}>
                        To embed Kognify as an iFrame window
                        <button className={styles.copyRedeemCodeButton} onClick={e => copyToClipboard(chatbotEmbed)}>
                            Get Embed Code
                        </button>
                    </div>
                </span>
                <div className={styles.editFormSectionTitle}>
                    <span>Content Configuration</span>
                </div>
                <TooltipHost
                    content="Allow Youtube Videos to be added to the Space. Already added videos will remain"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Enable Youtube Content Source"
                        disabled={false}
                        checked={editedSpace.allowyoutube == 1}
                        onChange={(_, checked) => handleChange("allowyoutube", checked ? 1 : 0)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Allow Web Pages to be added to the Space. Already added web pages will remain"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Enable Web Page Content Source"
                        disabled={false}
                        checked={editedSpace.allowweb == 1}
                        onChange={(_, checked) => handleChange("allowweb", checked ? 1 : 0)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Each content is split into smaller sections to be added to the Vector Index. This setting determines the legth in characters of each section (Requires Reindexing)"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Slider
                        styles={sliderStyles}
                        label="Document Section Length"
                        min={500}
                        max={15000}
                        step={100}
                        defaultValue={editedSpace.maxsectionlength}
                        showValue
                        onChange={value => handleChange("maxsectionlength", value)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Length of content in characters added at the start and end of each section, to help with keeping context between sections (Requres Reindexing)"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Slider
                        styles={sliderStyles}
                        label="Section Overlap"
                        min={100}
                        max={500}
                        step={10}
                        defaultValue={editedSpace.sectionoverlap}
                        showValue
                        onChange={value => handleChange("sectionoverlap", value)}
                    />
                </TooltipHost>
                <TooltipHost
                    content="Use Microsoft Azure OpenAI Service hosted on the Microsoft Cloud (contact for more options like OpenAI, Claude, Self-Hosted Llamma, others)"
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    calloutProps={tooltipStyles}
                >
                    <Checkbox
                        className={styles.editSpaceCheckbox}
                        styles={checkBoxStyles}
                        label="Use Microsoft Azure OpenAI Implementation"
                        disabled={true}
                        checked={editedSpace.isintegrateddata === 1}
                        onChange={(_, checked) => handleChange("isintegrateddata", checked ? 1 : 0)}
                    />
                </TooltipHost>

                <div className={styles.modalBottomButs}>
                    <IconButton className={styles.delSpaceButtons} iconProps={{ iconName: "Delete" }} onClick={handleDelete} />
                    <PrimaryButton text="Save" onClick={handleSave} disabled={isLoadingAPI} className={styles.saveSpaceButton} />
                </div>
            </Stack>
        </div>
    );
};

export default SpaceEditForm;
