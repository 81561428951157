import React, { useEffect, useState } from "react";
import { Stack, Toggle, IToggleProps, Dropdown, IDropdownOption, Button, IDropdownStyles } from "@fluentui/react";
import { ggroupcardprops, ggroupcardslistprops } from "./../../interfaces";
import styles from "./GroupCardsList.module.css";
import DocumentCardComponent from "./DocumentCardComponent";
import CreateNewSpaceCard from "./CreateNewSpaceCard";
import ShimmerDocumentCard from "./ShimmerDocumentCard";

// Import @dnd-kit components
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, rectSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const GroupCardsList: React.FC<ggroupcardslistprops> = ({ grL, onNewGroupClick, compLvl, permissionLevelsMap, newSpacePyro, navChatLib }) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const [displayKey, setDisplayKey] = useState(0);
    const [grLState, setGrLState] = useState(grL);

    const [compLvlState, setCompLvlState] = useState<number>(compLvl);
    const [permissionLevelsMapState, setPermissionLevelsMapState] = useState(permissionLevelsMap);

    const [onlyMySpaces, setOnlyMySpaces] = useState(false);
    const [grLMainState, setGrLMainState] = useState<ggroupcardprops[]>([]);
    const [grLHiddenState, setGrLHiddenState] = useState<ggroupcardprops[]>([]);
    const [activeId, setActiveId] = useState<string | null>(null);
    const [activeItem, setActiveItem] = useState<ggroupcardprops | null>(null);
    const [sortingProperty, setSortingProperty] = useState<string>(localStorage.getItem("spaceSortingProperty") || "oid");
    const [filteringProperty, setFilteringProperty] = useState<string>("all");
    const [manualOrder, setManualOrder] = useState<string[]>(localStorage.getItem("spaceManualOrder")?.split(",") || []);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 30
            }
        })
    );
    const mobileSensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 1000,
                tolerance: 0
            }
        })
    );
    function hasArrayChanged(newArray: ggroupcardprops[]): boolean {
        const newArrayString = JSON.stringify(newArray);
        let previousArrayString = JSON.stringify(grLState);
        const changed = previousArrayString !== newArrayString;
        previousArrayString = newArrayString;
        return changed;
    }
    const sortingOptions: IDropdownOption[] = [
        { key: "oid", text: "Newest" },
        { key: "dailymsgs", text: "Most Active" },
        { key: "filecount", text: "Most Content" },
        { key: "manual", text: "Manual Order" }
    ];
    const filterOptions: IDropdownOption[] = [
        { key: "all", text: "All" },
        { key: "mine", text: "Owned" },
        { key: "hidden", text: "Hidden" }
    ];
    const isTouchDevice = () => {
        return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    };
    useEffect(() => {
        if (grL.length == 0 || compLvlState < 1 || !permissionLevelsMapState) {
            return;
        }
        if (hasArrayChanged(grL)) {
        } else {
            return;
        }
        setGrLState(grL);
        if (manualOrder.length != grL.length) {
            let grLStrArr = grL.map(item => item.selectedGroup.selectionId);
            if (grL.length > manualOrder.length) {
                // grL has more strings, add them to the beginning of manualOrder
                const extraItems = grLStrArr.filter(item => !manualOrder.includes(item));
                console.log("¦lS¦1");
                localStorage.setItem("spaceManualOrder", [...extraItems, ...manualOrder].join(","));
                setManualOrder([...extraItems, ...manualOrder]);
            } else {
                console.log("¦lS¦2");
                // manualOrder has more strings, remove extras from grL
                localStorage.setItem("spaceManualOrder", manualOrder.filter(item => grLStrArr.includes(item))?.join(","));
                setManualOrder(manualOrder.filter(item => grLStrArr.includes(item)));
            }
        }
    }, [grL]);

    useEffect(() => {
        console.log("¦lS¦3¦1");
        if (grLState.length == 0 || compLvlState < 1 || !permissionLevelsMapState) {
            return;
        }
        console.log("¦lS¦3¦2", filteringProperty);
        setCompLvlState(compLvl);
        setPermissionLevelsMapState(permissionLevelsMap);
        const hiddenState = grL.filter((item: ggroupcardprops) => {
            return (localStorage.getItem("hiddenGroups") || "").indexOf(item.selectedGroup.selectionId) >= 0;
        });
        let mainState = grL.filter((item: ggroupcardprops) => {
            return filteringProperty == "all"
                ? (localStorage.getItem("hiddenGroups") || "").indexOf(item.selectedGroup.selectionId) < 0
                : filteringProperty == "mine"
                ? (localStorage.getItem("hiddenGroups") || "").indexOf(item.selectedGroup.selectionId) <= 0 &&
                  (!onlyMySpaces || item.selectedGroup.permissionlevel >= 3)
                : filteringProperty == "hidden"
                ? (localStorage.getItem("hiddenGroups") || "").indexOf(item.selectedGroup.selectionId) >= 0
                : true;
        });

        if (sortingProperty === "manual") {
            if (manualOrder.length > 0) {
                mainState = [
                    ...mainState.filter(item => !manualOrder.includes(item.selectedGroup.selectionId)),
                    ...manualOrder.map(id => mainState.find(item => item.selectedGroup.selectionId === id)).filter(Boolean)
                ] as ggroupcardprops[];
            } else {
                manageManualOrder(mainState.map(item => item.selectedGroup.selectionId));
            }
        } else {
            mainState = mainState.slice().sort((a, b) => {
                const getValue = (item: ggroupcardprops) => {
                    switch (sortingProperty) {
                        case "dailymsgs":
                            return item.selectedGroup.dailymsgs;
                        case "filecount":
                            return item.selectedGroup.filecount;
                        case "oid":
                            return item.selectedGroup.oid;
                        default:
                            return 0;
                    }
                };
                const aValue = getValue(a);
                const bValue = getValue(b);
                if (aValue < bValue) return 1;
                if (aValue > bValue) return -1;
                return 0;
            });
        }

        setGrLMainState(mainState);
        setGrLHiddenState(hiddenState);
        setDisplayKey(displayKey + 1);
    }, [refreshKey, grLState, onlyMySpaces, sortingProperty, filteringProperty]);

    const makeValidHtmlId = (str: string) => {
        let validStr = str.replace(/[^a-zA-Z0-9-_:.]/g, "");

        if (!/^[a-zA-Z]/.test(validStr)) {
            validStr = "id-" + validStr;
        }

        if (validStr.length === 0) {
            validStr = "id";
        }

        return validStr;
    };

    const handleChange: IToggleProps["onChange"] = (_event, checked) => {
        setOnlyMySpaces(checked ?? false);
    };

    const onSortingChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            localStorage.setItem("spaceSortingProperty", option.key as string);
            setSortingProperty(option.key as string);
        }
    };
    const onFilteringChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setFilteringProperty(option.key as string);
            if (option.key === "mine") {
                setOnlyMySpaces(true);
            } else {
                setOnlyMySpaces(false);
            }
        }
    };
    // DnD Handlers
    const handleDragStart = (event: any) => {
        const { active } = event;
        setActiveId(active.id);
        const draggedItem = grLMainState.find(item => item.selectedGroup.selectionId === active.id);
        setActiveItem(draggedItem || null);
    };

    const manageManualOrder = (newOrder: string[]) => {
        setManualOrder(newOrder);
        localStorage.setItem("spaceManualOrder", newOrder.join(","));
    };
    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over?.id && over) {
            const oldIndex = grLMainState.findIndex(item => item.selectedGroup.selectionId === active.id);
            const newIndex = grLMainState.findIndex(item => item.selectedGroup.selectionId === over.id);

            setGrLMainState(items => {
                const newItems = arrayMove(items, oldIndex, newIndex);
                // Update manualOrder
                manageManualOrder(newItems.map(item => item.selectedGroup.selectionId));

                return newItems;
            });
        }
        setActiveId(null);
        setActiveItem(null);

        if (sortingProperty != "manual") {
            setSortingProperty("manual");
            localStorage.setItem("spaceSortingProperty", "manual");
        }
    };

    const handleDragCancel = () => {
        setActiveId(null);
        setActiveItem(null);
    };

    // Custom Hook for Sortable Items
    const SortableItem = (props: { item: ggroupcardprops; index: number }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
            id: props.item.selectedGroup.selectionId
        });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition
        };
        const handleRefreshKey = () => {
            setRefreshKey(refreshKey + 1);
        };
        return (
            <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <DocumentCardComponent
                    index={props.index}
                    item={props.item}
                    makeValidHtmlId={makeValidHtmlId}
                    newSpacePyro={newSpacePyro}
                    permissionLevelsMap={permissionLevelsMapState}
                    tooltipStyles={{}}
                    setRefreshKey={handleRefreshKey}
                    hidden={filteringProperty == "hidden"}
                    navChatLib={navChatLib}
                />
            </div>
        );
    };
    const handleChangeTag = () => {
        setOnlyMySpaces(prevState => !prevState);
        console.log("onlyMySpaces is now:", !onlyMySpaces);
    };
    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            padding: "0",
            margin: "0 5px 0 0",

            borderRadius: "5px 5px 0 0 !important",
            color: "white",
            borderColor: "grey",
            width: "115px",
            selectors: {
                ":focus": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":hover": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white !important"
                },
                ":active": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":after": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":before": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                }
            }
        },
        label: {
            fontFamily: "Urbanist",
            fontSize: "12px",

            borderRadius: "5px !important",
            borderColor: "grey",
            width: "100%",
            color: "white !important",
            marginBottom: "5px"
        },
        title: {
            fontFamily: "Urbanist",
            borderRadius: "5px 5px 0 0 !important",
            borderColor: "grey",
            fontSize: "12px",
            height: "30px",
            width: "100%",
            background: "grey",
            color: "white !important",
            selectors: {
                ":focus": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":hover": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white !important"
                },
                ":active": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":after": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":before": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "#333"
                }
            }
        },

        dropdown: {
            borderRadius: "5px 5px 0 0 !important",
            color: "white",
            borderColor: "grey",

            selectors: {
                ":focus": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":hover": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white !important"
                },
                ":active": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":after": {
                    borderRadius: "5px !important",
                    borderColor: "grey !important",
                    color: "white"
                },
                ":before": {
                    borderRadius: "5px 5px 0 0 !important",
                    borderColor: "grey !important",
                    color: "#333"
                }
            }
        },
        dropdownItem: {
            fontFamily: "Urbanist",
            fontSize: "12px",
            height: "30px",
            color: "white",
            backgroundColor: "grey"
        },

        dropdownItemSelected: {
            fontFamily: "Urbanist",

            fontSize: "12px",
            height: "30px",
            color: "white",
            backgroundColor: "grey"
        },
        dropdownItemHeader: {
            fontFamily: "Urbanist",
            borderRadius: "15px !important",
            fontSize: "12px",
            height: "30px",
            color: "white !important"
        },

        caretDown: {
            borderRadius: "15px !important",
            color: "#333"
        }
    };
    return (
        <div key={displayKey} className={styles.carsdWrp}>
            {grLState.length > 5 ? (
                <div className={styles.controls}>
                    <div className={styles.mySpacesOnlySwitchInCenter}>
                        <Dropdown selectedKey={filteringProperty} options={filterOptions} onChange={onFilteringChange} styles={dropdownStyles} />
                        <Dropdown selectedKey={sortingProperty} options={sortingOptions} onChange={onSortingChange} styles={dropdownStyles} />
                    </div>
                </div>
            ) : null}

            {grLState.length === 0 ? (
                <Stack
                    horizontal
                    wrap
                    tokens={{ childrenGap: 20, padding: 20, maxWidth: "97vw" }}
                    styles={{ root: { justifyContent: "left", maxWidth: "1000px" } }}
                    className={styles.gridStk}
                >
                    {Array.from({ length: 3 }).map((_, index) => (
                        <ShimmerDocumentCard key={index} />
                    ))}
                </Stack>
            ) : (
                <DndContext
                    sensors={isTouchDevice() ? mobileSensors : sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={grLMainState.map(item => item.selectedGroup.selectionId)} strategy={rectSortingStrategy}>
                        <Stack
                            wrap
                            horizontal
                            tokens={{ childrenGap: 20, padding: 20, maxWidth: "97vw" }}
                            styles={{
                                root: {
                                    justifyContent: "center",
                                    maxWidth: "1000px"
                                },
                                inner: {
                                    justifyContent: "center",
                                    maxWidth: "1000px"
                                }
                            }}
                            className={styles.gridStk}
                        >
                            {compLvlState > 1 ? <CreateNewSpaceCard onNewGroupClick={onNewGroupClick} /> : null}

                            {grLMainState.map((item, index) => (
                                <SortableItem key={item.selectedGroup.selectionId} item={item} index={index} />
                            ))}
                        </Stack>
                    </SortableContext>

                    {/*
                    <DragOverlay>
                        {activeItem ? (
                            <DocumentCardComponent
                                index={-1}
                                item={activeItem}
                                makeValidHtmlId={makeValidHtmlId}
                                newSpacePyro={newSpacePyro}
                                permissionLevelsMap={permissionLevelsMapState}
                                tooltipStyles={{}}
                                setRefreshKey={setRefreshKey}
                                hidden={false}
                            />
                        ) : null}
                    </DragOverlay>
                    */}
                </DndContext>
            )}
            {/*
            {grLHiddenState.length > 0 && (
                <div>
                    <Stack
                        wrap
                        horizontal
                        tokens={{ childrenGap: 20, padding: 20, maxWidth: "97vw" }}
                        styles={{
                            root: {
                                justifyContent: "left",
                                maxWidth: "1000px",
                                display: "flex"
                            }
                        }}
                        className={styles.gridStk}
                    >
                        {grLHiddenState.map((item, index) => (
                            <div key={item.selectedGroup.selectionId}>
                                <DocumentCardComponent
                                    index={index}
                                    item={item}
                                    makeValidHtmlId={makeValidHtmlId}
                                    newSpacePyro={newSpacePyro}
                                    permissionLevelsMap={permissionLevelsMapState}
                                    tooltipStyles={{}}
                                    setRefreshKey={setRefreshKey}
                                    hidden={true}
                                    navChatLib={navChatLib}
                                />
                            </div>
                        ))}
                    </Stack>
                </div>
            )}
            */}
        </div>
    );
};

export default GroupCardsList;
