import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";
import { AnswerIcon } from "./AnswerIcon";
import DOMPurify from "dompurify";

interface hiMess {
    content: string;
}

export const AnswerHi = ({ content }: hiMess) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    const sanitizedAnswerHtml = content; //DOMPurify.sanitize(content);

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainerHI} verticalAlign="space-between">
                <AnswerIcon />
                <Stack.Item grow>
                    <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
