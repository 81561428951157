const BACKEND_URI = "";

import { ChatAppResponse, ChatAppResponseOrError, ChatAppRequest, IUploadResponse } from "./models";
import { useLogin } from "../authConfig";
import { toast } from "react-toastify";
import { gselectedgroup, greindexparams, gingestioniteminfo } from "../interfaces";

function getHeaders(idToken: string | undefined): Record<string, string> {
    var headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    // If using login, add the id token of the logged in account as the authorization
    if (useLogin) {
        if (idToken) {
            headers["Authorization"] = `Bearer ${idToken}`;
        }
    }
    return headers;
}

export async function chatApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function aiSearchApi(request: ChatAppRequest, idToken: string | undefined): Promise<Response> {
    return await fetch(`${BACKEND_URI}/aisearch`, {
        method: "POST",
        headers: getHeaders(idToken),
        body: JSON.stringify(request)
    });
}

export async function deleteFile(fileName: string, fileid: string, header: any, companyid: string, progressCallback?: () => void): Promise<IUploadResponse> {
    const toastId = toast("Deleting Files. Prepping...", { autoClose: false });
    const response = await fetch("/fileRemove", {
        method: "POST",
        headers: header,
        body: JSON.stringify({ fileName: fileName, fileid: fileid, companyid: companyid })
    });
    if (!response.ok) {
        throw new Error(`Delete File Failed: ${response.statusText}`);
    }

    toast.update(toastId, { render: "Deleting...", autoClose: false });

    const dataResponse: any = await response.json();
    const taskId = dataResponse.task_id;

    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const fetchDelTaskStatus = async (taskId: string) => {
        const statusResponse = await fetch(`/task-status/${taskId}`);
        if (!statusResponse.ok) {
            throw new Error(`Fetching Task Status Failed: ${statusResponse.statusText}`);
        }
        return await statusResponse.json();
    };
    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";

    while (!taskCompleted) {
        await wait(1000);
        const chkResp = await fetchDelTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("deleteFileApi¦Next Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            console.log("Task Completed:", chkResp);
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 2000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Deleting File...`);
            if (progressCallback) {
                progressCallback();
            }
            toast.update(toastId, {
                render: chkResp.message
            });
        }
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}

function removeTaskIdFromLocal(taskId: string) {
    let taskIds = localStorage.getItem("taskIds")?.split(";");
    taskIds = taskIds?.filter(id => id !== taskId);
    localStorage.setItem("taskIds", taskIds ? taskIds.join(";") : "");
}

export async function reindexApi(props: greindexparams, progressCallback?: () => void): Promise<IUploadResponse> {
    console.log("REINDEX¦", props);

    const response = await fetch("/reindexDocs", {
        method: "POST",
        headers: props.header,
        body: JSON.stringify({
            filestring: props.fileString,
            userid: props.userId,
            company: props.company,
            filespace: props.selectedGroup.selectionId,
            sectionoverlap: props.selectedGroup.sectionoverlap,
            maxsectionlength: props.selectedGroup.maxsectionlength,
            sentencesearchlimit: props.selectedGroup.sentencesearchlimit,
            modstring: props.modString,
            idstring: props.idString,
            invoicesgroup: props.selectedGroup.invoicesgroup
        })
    });

    if (!response.ok) {
        throw new Error(`Reindexing Files Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response", dataResponse);

    const taskId = dataResponse.task_id;

    let itterCounter = 0;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    const toastId = toast("Reindexing Files...", { autoClose: false });

    while (!taskCompleted) {
        itterCounter++;
        // reindexApi
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            console.log("Task Completed:", chkResp);
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Reindexing Files...`);
            if (chkResp.fileCount !== undefined) {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Reindexing Files... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                } catch {
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Reindexing Files... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }

        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        console.log("MSG", taskMessage);
        taskMessagePrev = taskMessage;
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}

export async function uploadFileApi(request: FormData, progressCallback?: () => void): Promise<IUploadResponse> {
    const toastId = toast("Uploading Files...", { autoClose: false });
    let isRequestFinished = false;
    setTimeout(() => {
        if (!isRequestFinished) {
            if (progressCallback) {
                progressCallback();
            }
        }
    }, 2000);

    const response = await fetch("/upload", {
        method: "POST",
        body: request
    });
    if (!response.ok) {
        throw new Error(`Uploading_Files_Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response", dataResponse);

    const taskId = dataResponse.task_id;
    //addTaskIdToLocal(taskId);

    let taskCompleted = false;
    let taskResult = false;
    let taskMessage = "";
    let taskMessagePrev = "-1";
    let itterCounter = 0;

    while (!taskCompleted) {
        itterCounter++;
        // uploadFileApi
        await wait(8000);
        const chkResp = await fetchTaskStatus(taskId);

        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            console.log("Task Completed:", chkResp);
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            if (chkResp.filecount !== undefined) {
                try {
                    let progress = chkResp.progresscount / chkResp.filecount;
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                } catch {
                    taskMessage = `Processing Files... ${chkResp.progresscount} of ${chkResp.filecount} Done`;
                    toast.update(toastId, {
                        render: taskMessage
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }
        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }
    removeTaskIdFromLocal(taskId);
    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}

export async function addURLApi(
    urlString: string,
    selectedGroup: gselectedgroup,
    company: string,
    claimsUserId: string,
    cTy: string,
    itemInfo: gingestioniteminfo[],
    progressCallback?: () => void
): Promise<IUploadResponse> {
    const response = await fetch("/addURL", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            url: urlString,
            fileSpace: selectedGroup.selectionId,
            company: company,
            userId: claimsUserId,
            cty: cTy,
            sectionoverlap: selectedGroup.sectionoverlap,
            maxsectionlength: selectedGroup.maxsectionlength,
            sentencesearchlimit: selectedGroup.sentencesearchlimit,
            fileInfo: { ...itemInfo }
        })
    });

    let messageArr = ["Downloading Page", "Preparing Page for Ingestion", "Creating Embeddings", "Adding Indices"];
    if (cTy == "youtube") {
        messageArr = [
            "Downloading Video",
            "Running Optical Character Recognition",
            "Capturing Key Frames",
            "Running Whisper on audio",
            "Putting it all together",
            "Creating Embeddings",
            "Adding Indices"
        ];
    }

    if (!response.ok) {
        throw new Error(`Add URL File Failed: ${response.statusText}`);
    }
    const toastId = toast("Processing URL...", { autoClose: false });

    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("addURLApi¦Init Response", dataResponse);

    const taskId = dataResponse.task_id;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    let itterCounter = 0;
    let messageIndex = 0;

    let finalMessage = "";

    while (!taskCompleted) {
        itterCounter++;
        // addURLApi
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("addURLApi¦Next Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            console.log("Task Completed:", chkResp);
            taskCompleted = true;
            finalMessage = `${chkResp.message}`;
        } else {
            if (itterCounter % 10 === 0) {
                if (messageIndex < messageArr.length - 1) {
                    messageIndex++;
                }
            }
            console.log("MSG_INDX", messageIndex, messageArr.length);
            if (chkResp.fileCount !== undefined) {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `${messageArr[messageIndex]}... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                } catch {
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Processing Locations... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                }
            }
            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }
        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        taskMessagePrev = taskMessage;
    }

    toast.update(toastId, {
        render: `Items Processed`,
        autoClose: 7000
    });
    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}

export async function dataExtractionApi(props: greindexparams, progressCallback?: () => void): Promise<IUploadResponse> {
    console.log("DATA_EXTRACTION¦", props);

    const response = await fetch("/invoiceExtraction", {
        method: "POST",
        headers: props.header,
        body: JSON.stringify({
            filestring: props.fileString,
            userid: props.userId,
            company: props.company,
            filespace: props.selectedGroup.selectionId,
            sectionoverlap: props.selectedGroup.sectionoverlap,
            maxsectionlength: props.selectedGroup.maxsectionlength,
            sentencesearchlimit: props.selectedGroup.sentencesearchlimit,
            modstring: props.modString,
            idstring: props.idString
        })
    });

    if (!response.ok) {
        throw new Error(`Extract Invoice Details Failed: ${response.statusText}`);
    }
    const dataResponse: any = await response.json();
    import.meta.env.DEV === true && console.log("pldFlAp¦Init_Response", dataResponse);

    const taskId = dataResponse.task_id;

    let itterCounter = 0;

    let taskCompleted = false;
    let taskResult = false;

    let taskMessage = "";
    let taskMessagePrev = "-1";

    const toastId = toast("Extracting Invoice Details...", { autoClose: false });

    if (progressCallback) {
        progressCallback();
    }

    while (!taskCompleted) {
        itterCounter++;
        // invoiceExtractionApi
        await wait(2000);
        const chkResp = await fetchTaskStatus(taskId);
        import.meta.env.DEV === true && console.log("pldFlAp¦Next_Response", chkResp);

        if (chkResp.status == "success" || chkResp.status == "partial" || chkResp.status == "error") {
            if (chkResp.status == "success" || chkResp.status == "partial") {
                taskResult = true;
                taskMessage = chkResp.message;
            } else {
                taskResult = false;
                taskMessage = chkResp.message;
            }
            console.log("Task Completed:", chkResp);
            taskCompleted = true;
            toast.update(toastId, {
                render: chkResp.message,
                autoClose: 7000
            });
        } else {
            import.meta.env.DEV === true && console.log("Toast", `Extracting Invoice Details...`);
            if (chkResp.fileCount !== undefined) {
                try {
                    let progress = chkResp.progressCount / chkResp.fileCount;
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Extracting Invoice Details... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                } catch {
                    taskMessage = chkResp.message;
                    toast.update(toastId, {
                        render: `Extracting Invoice Details... ${chkResp.progressCount} of ${chkResp.fileCount} Done`
                    });
                }
            }

            if (itterCounter % 10 === 0 || itterCounter % 10 === 5 || itterCounter < 5) {
                if (progressCallback) {
                    progressCallback();
                }
            }
        }

        if (taskMessagePrev !== taskMessage) {
            if (progressCallback) {
                progressCallback();
            }
        }
        console.log("MSG", taskMessage);
        taskMessagePrev = taskMessage;
    }

    const uplResponse: IUploadResponse = { success: taskResult, message: taskMessage };
    return uplResponse;
}

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const fetchTaskStatus = async (taskId: string) => {
    try {
        const statusResponse = await fetch(`/task-status/${taskId}`);
        if (!statusResponse.ok) {
            console.log("Fetching Task Status Failed: ", statusResponse.statusText);
            return {};
        }
        return await statusResponse.json();
    } catch (error) {
        console.error("Error fetching task status:", error);
        return {};
    }
};

export const downloadPDF = (url: string, filename: string, companyId: string) => {
    fetch(`/content/${companyId}/${url}`)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error("Download failed:", error));
};
