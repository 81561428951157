import React from "react";
import { DocumentCard, DocumentCardTitle } from "@fluentui/react/lib/DocumentCard";
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";
import { DirectionalHint } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineMessage } from "react-icons/ai";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { BiSolidHide, BiShowAlt } from "react-icons/bi";
import styles from "./GroupCardsList.module.css";
import frwrkStyles from "./FireWorks.module.css";
import { SettingsButton } from "../../components/SettingsButton";

import { GrAddCircle } from "react-icons/gr";
// Define the interface for props
interface DocumentCardComponentProps {
    index: number;
    item: any;
    makeValidHtmlId: (id: string) => string;
    newSpacePyro: string;
    permissionLevelsMap: { [key: number]: string };
    tooltipStyles: any;
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
    hidden: boolean;
    navChatLib: (loc: string, groupid: string) => void;
}

const DocumentCardComponent: React.FC<DocumentCardComponentProps> = ({
    index,
    item,
    makeValidHtmlId,
    newSpacePyro,
    permissionLevelsMap,
    tooltipStyles,
    setRefreshKey,
    hidden,
    navChatLib
}) => {
    return (
        <DocumentCard
            key={index}
            id={`${makeValidHtmlId(item.selectedGroup.selectionId)}`}
            styles={{
                root: {
                    width: 300,
                    marginBottom: 0,
                    marginTop: 20,
                    height: "100%",
                    borderRadius: 10,
                    backgroundColor: "rgba(193, 200, 214,0.16)",
                    padding: "10px 20px 20px 20px",
                    position: "relative",
                    border: "none",
                    transition: "box-shadow 0.1s ease-in-out",
                    selectors: {
                        ":hover": {
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            border: "none"
                        },
                        ":hover:after": {
                            border: "none"
                        },
                        ":active": {
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                        },
                        ":active:after": {
                            border: "none"
                        }
                    }
                }
            }}
            onClick={() => item.handleGroupListClick(item.selectedGroup.selectionId, item.selectedGroup.selectionText, item.selectedGroup.permissionlevel)}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                {/* SPACE TITLE */}
                <DocumentCardTitle
                    title={item.selectedGroup.selectionText}
                    styles={{
                        root: {
                            font: "normal normal 1000 18px/22px Urbanist, Roboto",
                            color: "#485a73",
                            textAlign: "left",
                            marginLeft: "-20px",
                            marginBottom: -18,
                            height: "auto"
                        }
                    }}
                />
                {newSpacePyro === item.selectedGroup.selectionId && (
                    <div className={frwrkStyles.fireworksContainer}>
                        <div className={frwrkStyles.pyro}>
                            <div className={frwrkStyles.before}></div>
                            <div className={frwrkStyles.after}></div>
                        </div>
                    </div>
                )}
                {hidden == false ? (
                    <div className={styles.spaceCardExtraInfo}>
                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    display: "flex",
                                    textAlign: "left",
                                    paddingBottom: 5,
                                    font: "normal normal normal 12px/19px Urbanist, Roboto",
                                    color: "#9baebc",
                                    verticalAlign: "middle",
                                    alignContent: "center"
                                }
                            }}
                        >
                            <TooltipHost content="Number of Documents in Space" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                                <div className={styles.flCGr}>
                                    <div
                                        style={{
                                            marginLeft: "-4px",
                                            marginRight: "5px",
                                            height: "20px",
                                            alignItems: "center",
                                            display: "flex"
                                        }}
                                    >
                                        <IoDocumentTextOutline size={15} />
                                        <div style={{ fontSize: "13px", marginLeft: "3px" }}>{item.selectedGroup.filecount}</div>
                                    </div>
                                </div>
                            </TooltipHost>{" "}
                            {item.selectedGroup.permissionlevel > 2 && (
                                <TooltipHost
                                    content="Message exchanges done today"
                                    directionalHint={DirectionalHint.bottomLeftEdge}
                                    calloutProps={tooltipStyles}
                                >
                                    ·
                                    <div className={styles.flCGr}>
                                        <div
                                            style={{
                                                marginTop: "-14px",
                                                marginLeft: "1px",
                                                marginRight: "5px",
                                                height: "10px",
                                                alignItems: "center",
                                                display: "flex"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginBottom: "-5px",
                                                    marginLeft: "5px",
                                                    marginRight: "3px"
                                                }}
                                            >
                                                <AiOutlineMessage size={15} />
                                            </div>{" "}
                                            <div style={{ fontSize: "13px", marginLeft: "3px" }}>{item.selectedGroup.dailymsgs} </div>
                                        </div>
                                    </div>
                                </TooltipHost>
                            )}
                            <div className={styles.spaceBeforeOwner}>· </div>
                            <TooltipHost content="Your Permission Level" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                                <div className={styles.permissionLevelText}> {permissionLevelsMap[item.selectedGroup.permissionlevel]}</div>
                            </TooltipHost>
                        </Text>
                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    textAlign: "left",
                                    paddingBottom: 5,
                                    font: "normal normal normal 12px/19px Urbanist, Roboto",
                                    color: "#797C95"
                                }
                            }}
                        ></Text>
                        {/*LICENSE PREMIUM ICON*/}
                        <div className={styles.spaceDetailsIcons}>
                            {item.selectedGroup.premiumby !== "" && (
                                <Text
                                    variant="medium"
                                    title="Premium Space"
                                    styles={{
                                        root: {
                                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                                            color: "#797C95"
                                        }
                                    }}
                                >
                                    <MdOutlineWorkspacePremium />
                                </Text>
                            )}

                            {item.selectedGroup.inscope === 0 && (
                                <Text
                                    variant="medium"
                                    title="General Chat (allow answers to anything)"
                                    styles={{
                                        root: {
                                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                                            color: "#797C95",
                                            marginLeft: "10px"
                                        }
                                    }}
                                >
                                    <Icon iconName="Globe" />
                                </Text>
                            )}
                        </div>

                        <Text
                            variant="medium"
                            styles={{
                                root: {
                                    textAlign: "left",
                                    paddingBottom: "30px",
                                    font: "normal normal normal 15px/17px Urbanist, Roboto",
                                    color: "#485a73"
                                }
                            }}
                        >
                            {item.selectedGroup.description}
                        </Text>
                    </div>
                ) : (
                    <div className={styles.spaceCardExtraInfo}> </div>
                )}

                <Text
                    variant="medium"
                    styles={{
                        root: {
                            position: "absolute",
                            bottom: 10,
                            right: 20,
                            textAlign: "right",
                            height: 25,
                            paddingBottom: 0,
                            font: "normal normal normal 16px/19px Urbanist, Roboto",
                            color: "#97accf",
                            width: "87%"
                        }
                    }}
                >
                    <div className={styles.greyLine} />
                    {hidden == true ? (
                        <div
                            className={styles.hideSpaceIcon}
                            onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                localStorage.setItem("hiddenGroups", (localStorage.getItem("hiddenGroups") || "").replace(item.selectedGroup.selectionId, ""));
                                setRefreshKey(oldKey => oldKey + 1);
                            }}
                        >
                            <TooltipHost
                                content="Return Assistant to your main 'All' Section"
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                calloutProps={tooltipStyles}
                            >
                                <BiShowAlt />
                            </TooltipHost>
                        </div>
                    ) : (
                        <div
                            className={styles.hideSpaceIcon}
                            onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                localStorage.setItem("hiddenGroups", `${localStorage.getItem("hiddenGroups") || ""},${item.selectedGroup.selectionId}`);
                                setRefreshKey(oldKey => oldKey + 1);
                            }}
                        >
                            <TooltipHost
                                content="Hide Assistant. You will be able to find it in the 'Hidden' Section"
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                calloutProps={tooltipStyles}
                            >
                                <BiSolidHide size={17} />
                            </TooltipHost>
                        </div>
                    )}
                    {hidden == false && (
                        <div className={styles.headSpaceButts}>
                            <TooltipHost content="Chat with this Assistant" directionalHint={DirectionalHint.bottomLeftEdge} calloutProps={tooltipStyles}>
                                <SettingsButton
                                    className={`${styles.settingsButton}`}
                                    onClick={() => {
                                        //navChatLib("chat", item.selectedGroup.selectionId);
                                        item.handleGroupListClick(
                                            item.selectedGroup.selectionId,
                                            item.selectedGroup.selectionText,
                                            item.selectedGroup.permissionlevel
                                        );
                                    }}
                                    buttonText="Chat"
                                    iconId="PiChatTeardropDotsBold"
                                    forceIcon={true}
                                />
                            </TooltipHost>
                            <TooltipHost
                                content="View Content for this Assistant"
                                directionalHint={DirectionalHint.bottomLeftEdge}
                                calloutProps={tooltipStyles}
                            >
                                <SettingsButton
                                    className={`${styles.settingsButton}`}
                                    onClick={() => {
                                        navChatLib("lib", item.selectedGroup.selectionId);
                                        //item.handleGroupListClick(item.selectedGroup.selectionId, item.selectedGroup.selectionText, item.selectedGroup.permissionlevel)
                                    }}
                                    buttonText="Manage"
                                    iconId="TbSettings2"
                                    forceIcon={true}
                                />
                            </TooltipHost>
                        </div>
                    )}
                </Text>
            </Stack>
        </DocumentCard>
    );
};

export default DocumentCardComponent;
