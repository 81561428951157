import React from "react";
import styles from "./AssistantTypeCards.module.css";
import { DocumentCard, DocumentCardTitle, DocumentCardDetails, DocumentCardImage } from "@fluentui/react/lib/DocumentCard";
import { ThemeProvider } from "@fluentui/react";
import { ImageFit } from "@fluentui/react/lib/Image";
import pps from "../../assets/pps.png";
import hda from "../../assets/hda.png";
import aas from "../../assets/aas.png";
import ipa from "../../assets/ipa.png";
import tam from "../../assets/tam.png";
import customTheme from "./../../theme/customTheme";
import { add } from "lodash";

interface assistantTypeProps {
    addSpace: (specializedSettings: any) => void;
}
const AssistantTypeCards: React.FC<assistantTypeProps> = ({ addSpace }) => {
    const cardItems = [
        {
            title: "Policies & Procedures Assistant",
            description: "Provides quick, accurate and up-to-date information on company policies and procedures.",
            imageSrc: pps,
            settings: {},
            onClick: () => addSpace({})
        },
        {
            title: "IT Helpdesk Assistant",
            description: "Optimized for IT support, troubleshooting, FAQs. Provides solutions and sources increasing first call resolution.",
            imageSrc: hda,
            settings: {},
            onClick: () => addSpace({})
        },
        {
            title: "Automation API Agent",
            description: "Configured to provide programatic AI responses to API calls.",
            imageSrc: aas,
            onClick: () =>
                addSpace({
                    invoicesgroup: 1
                })
        },
        {
            title: "Invoice Processing Agent",
            description: "Fully automated invoice processing and reporting. Invoice Data extraction and validation.",
            imageSrc: ipa,
            settings: {},
            onClick: () => addSpace({})
        },
        {
            title: "Technical Onboarding Mentor",
            description: "Optimized for technical documentation, onboarding and training.",
            imageSrc: tam,
            onClick: () => addSpace({})
        }
    ];

    return (
        <div className={styles.cardContainer}>
            {cardItems.map((item, index) => (
                <ThemeProvider theme={customTheme}>
                    <DocumentCard key={index} className={styles.card} onClick={item.onClick}>
                        <div className={styles.overlay}>
                            <span className={styles.overlayText}>Create</span>
                        </div>
                        <DocumentCardImage height={150} imageFit={ImageFit.centerCover} imageSrc={item.imageSrc} />

                        <DocumentCardDetails>
                            <DocumentCardTitle title={item.title} className={styles.cardTitle} />
                            <DocumentCardTitle title={item.description} shouldTruncate showAsSecondaryTitle className={styles.cardDesc} />
                        </DocumentCardDetails>
                    </DocumentCard>
                </ThemeProvider>
            ))}
        </div>
    );
};

export default AssistantTypeCards;
