import { gfile, gcontentlist, gpagemap, gselectedgroup, greindexparams } from "../../interfaces";
import { fontFace, mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
    IComboBox,
    IComboBoxOption,
    IconButton,
    Dropdown,
    IDropdownOption,
    IRenderFunction,
    IDetailsHeaderProps,
    DetailsHeader,
    TooltipHost,
    DirectionalHint,
    IObjectWithKey,
    CommandBar,
    ICommandBarItemProps,
    mergeStyles,
    ITextFieldStyles,
    IStyle
} from "@fluentui/react";
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode,
    IColumn,
    IDetailsRowProps,
    DetailsRow,
    IDetailsRowStyles
} from "@fluentui/react/lib/DetailsList";

export function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export function parseDocuments(
    documents: {
        fileid: string;
        name: string;
        size: string;
        full_path: string;
        modified: string;
        groupids: string;
        groupnames: string;
        filerole: string;
        ownerid: string;
        filepermlevel: number;
        contentlist: gcontentlist[];
        pagemap: gpagemap;
        sourcetype: string;
        sourceurl: string;
        docstatus: string;
        invoicedata?: string;
        doc_category: string;
    }[]
) {
    const items: IDocument[] = [];
    for (let i = 0; i < documents.length; i++) {
        const randomDate = documents[i].modified;
        const randomFileSize = `${bytesToMB(parseInt(documents[i].size))}`;

        let randomFileType = getFileIcon(13); // PDF

        let fileName = documents[i].name;
        let fileid = documents[i].fileid;

        let displayName = documents[i].name;
        try {
            if (documents[i].sourcetype == "youtube") {
                if (documents[i].docstatus == "Ready") {
                    if (documents[i].name != undefined && documents[i]?.name != null) {
                        displayName = documents[i]?.name?.split("_").slice(2).join("_").replace(".pdf", "");
                    } else {
                        displayName = "No Name";
                    }
                } else {
                    displayName = documents[i].name;
                }
            } else if (documents[i].sourcetype == "web") {
                displayName = documents[i].name.replace(".pdf", "");
            }
        } catch {
            console.log("ERR¦Doc_Processing", documents[i]);
        }

        items.push({
            key: fileid,
            name: fileName,
            value: fileName,
            iconName: randomFileType.url,
            fileType: randomFileType.docType,
            modifiedBy: "",
            dateModified: randomDate,
            dateModifiedValue: documents[i].modified ? new Date(documents[i].modified).getTime() : new Date(1900, 0, 1).getTime(),
            fileSize: randomFileSize,
            fileSizeRaw: 0,
            full_path: documents[i].full_path,
            groupIds: documents[i]?.groupids?.split(","),
            groupNames: documents[i]?.groupnames?.split(","),
            filerole: documents[i].filerole,
            ownerid: documents[i].ownerid,
            filepermlevel: documents[i].filepermlevel,
            contentlist: documents[i].contentlist,
            pagemap: documents[i].pagemap,
            sourcetype: documents[i].sourcetype,
            sourceurl: documents[i].sourceurl,
            docstatus: documents[i].docstatus,
            displayName: displayName,
            invoicedata: documents[i].invoicedata,
            doc_category: documents[i].doc_category
        });
    }
    return items;
}

export function formatDateTime(dateString: string) {
    const date = new Date(dateString);
    return date.toISOString().replace(/T/, " ").replace(/\..+/, "").replace(/-/g, ".");
}

export function getFileIcon(type: number): { docType: string; url: string } {
    const docType: string = FILE_ICONS[type].name;
    return {
        docType,
        url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`
    };
}

export function bytesToMB(bytes: number) {
    const megabytes = bytes / 1000000;
    return `${megabytes.toFixed(1)} MB`;
}

export const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        font: "normal normal medium 20px/24px Urbanist,Roboto;",
        letterSpacing: "0px",
        color: "#333333"
    },
    fileIconCell: {
        textAlign: "center",
        selectors: {
            "&:before": {
                content: ".",
                display: "inline-block",
                verticalAlign: "middle",
                height: "100%",
                width: "0px",
                visibility: "hidden"
            }
        }
    },
    fileIconImg: {
        verticalAlign: "middle",
        maxHeight: "16px",
        maxWidth: "16px"
    },
    controlWrapper: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f0f0f0",

        borderRadius: "5px"
    },
    exampleToggle: {
        display: "inline-block",
        marginBottom: "10px",
        marginRight: "30px"
    },
    selectionDetails: {
        marginBottom: "20px"
    },
    pdfV: {
        height: "auto"
    },
    shimmerOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "40vh",
        background: "linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%)",
        zIndex: 1,

        pointerEvents: "none"
    }
});

export const pdfI = <img src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/pdf.svg`} className={classNames.fileIconImg} />;

export const FILE_ICONS: { name: string }[] = [
    { name: "accdb" },
    { name: "audio" },
    { name: "code" },
    { name: "csv" },
    { name: "docx" },
    { name: "dotx" },
    { name: "mpp" },
    { name: "mpt" },
    { name: "model" },
    { name: "one" },
    { name: "onetoc" },
    { name: "potx" },
    { name: "ppsx" },
    { name: "pdf" },
    { name: "photo" },
    { name: "pptx" },
    { name: "presentation" },
    { name: "potx" },
    { name: "pub" },
    { name: "rtf" },
    { name: "spreadsheet" },
    { name: "txt" },
    { name: "vector" },
    { name: "vsdx" },
    { name: "vssx" },
    { name: "vstx" },
    { name: "xlsx" },
    { name: "xltx" },
    { name: "xsn" }
];

export interface IDetailsListDocumentsExampleProps {
    fileList: gfile[] | undefined;
    selectionPass: string;
    selectedGroup: gselectedgroup;
    onSelectionChange: (newSelection: { name: string; groupIds: string; groupNames: string }[]) => void;
    groupList: IDropdownOption[];
    updateCatsState: (newVal: string) => void;
    fileSpaceChangeTrigger: (timestamp: string) => void;
    getOFromListVal: number;
    openSpacesEditPanel: () => void;
    openSpacesAnalyticsPanel: () => void;
    user: { userDisplayName: string; userId: string; userMail: string };
    loggedInUser: { userId: string; mail: string; token: string };
    onDelFile: () => void;
    onCitationClicked: (filePath: string, integratedCitation: string | null) => void;
    companyId: string;
    listRefreshRequest: () => void;
}

export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection?: boolean;
    isCompactMode?: boolean;
    announcedMessage?: string;
    itemSelectionString?: string;
    pathSelectionString?: string[];
    isModalOpen: boolean;
    isSecurityModalOpen: boolean;
    isContextMenuVisible: boolean;
    isSpaceFilterVisible: boolean;
    contextMenuTarget: any; // Right Click Context Menu Target
    isWorkingOnDelete: boolean;
    spacesFilter: string;
    activeFile: any;
    hasDeleted: boolean;
    invoiceModalItem: IDocument | undefined;
    summaryModalItem: any[] | undefined;
    targetItem: any | undefined;
    callOutVisibilityBool: boolean;
    aiSearchData: any | undefined;
}

export interface IDocument {
    key: string;
    name: string;
    value: string;
    iconName: string;
    fileType: string;
    modifiedBy: string;
    dateModified: string;
    dateModifiedValue: number;
    fileSize: string;
    fileSizeRaw: number;
    full_path: string;
    groupNames: string[];
    groupIds: string[];
    filerole: string;
    ownerid: string;
    filepermlevel: number;
    contentlist: gcontentlist[];
    pagemap: gpagemap;
    isDeleting?: boolean;
    sourcetype: string;
    sourceurl: string;
    docstatus: string;
    displayName: string;
    invoicedata?: string;
    doc_category: string;
}

export const headerContainer = mergeStyles({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f0f0f0"
});

export const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        selectors: {
            label: {
                fontFamily: "Urbanist"
            }
        }
    },
    prefix: {
        backgroundColor: "transparent"
    },
    root: {
        borderRadius: "5px"
    },
    fieldGroup: {
        borderRadius: "5px",
        border: "1px solid #E1E4E7",
        selectors: {
            "::after": {
                borderRadius: "5px ",
                border: "2px solid #333"
            }
        }
    },
    subComponentStyles: {
        label: {
            root: {
                fontSize: "14px",
                fontFamily: "Urbanist"
            }
        }
    }
};

export const controlStyles = {
    root: {
        margin: "0",
        paddingTop: "0px !important",
        width: "100%",
        maxWidth: "100vw",
        overflowY: "scroll"
    },
    commandButton: {
        marginRight: "20px",
        marginBottom: "20px"
    },
    headerWrapper: {
        position: "sticky",
        top: 0,
        zIndex: 1
    } as IStyle,
    detailsHeader: { padding: "0px" }
};

export const customStylesDeLi: Partial<IDetailsRowStyles> = {
    root: {
        selectors: {
            "&:hover": {
                background: "transparent"
            }
        }
    },
    cell: {
        opacity: 0.5,
        pointerEvents: "none"
    }
};
export const actionButtonStyles = {
    iconHovered: { color: "#333333" }
};
