import React from "react";
import { useRef, useState, useEffect } from "react";
import { DetailsList, IColumn, DetailsListLayoutMode, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import styles from "./CompList.module.css";
import { PrimaryButton } from "@fluentui/react";
import InviteModal from "../InviteModal/InviteModal";

//import { useMsal } from "./../../MsalContext";
//import { useMsal } from "@azure/msal-react";

interface ICompListProps {
    comps: { companyid: string; companyname: string; permissionlevel: number }[];
    newActiveComp: (newActiveComp: { companyid: string; companyname: string; permissionlevel: number }) => void;
    activeComp: any;
}

const columns: IColumn[] = [
    { key: "column1", name: "Id", fieldName: "companyid", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "column2", name: "Company Name", fieldName: "companyname", minWidth: 100, maxWidth: 200, isResizable: true }
];

const CompList: React.FC<ICompListProps> = ({ comps, newActiveComp, activeComp }) => {
    //const { msalInstance, getToken } = useMsal();
    const [selectedCompany, setSelectedCompany] = useState<any>("");
    const selection = new Selection({
        onSelectionChanged: () => {
            const selectedItem = selection.getSelection()[0] as { companyid: string; companyname: string; permissionlevel: number };
            setSelectedCompany(selectedItem ? selectedItem : {});
            //console.log(selectedItem);
        },
        selectionMode: SelectionMode.single
    });
    return (
        <div className={styles.contMa}>
            <div className={styles.userListHead}>
                <PrimaryButton
                    className={styles.changeActiveComp}
                    text="Change Active Company"
                    disabled={selectedCompany === "" || selectedCompany === activeComp}
                    iconProps={{ iconName: "List" }}
                    onClick={() => {
                        newActiveComp(selectedCompany);
                    }}
                />
            </div>
            <DetailsList
                items={comps}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified} // Adjust layout mode as needed
                selectionPreservedOnEmptyClick={true}
                selection={selection}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                onItemInvoked={item => {
                    setSelectedCompany(item);
                    //console.log("onItemInvoked", item.companyid);
                    newActiveComp(item);
                }}
            />
        </div>
    );
};

export default CompList;
